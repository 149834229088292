.teams-and-players {

	&__content {
		
		position: relative;
		
		@media screen and (min-width: 992px) {}
		
	}

}
