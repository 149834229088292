.league-tables {
	
	&__group {
		
		&__table {
			
			&__header {
				
				display: none;
				background: #E6E6E6;
				
				@media screen and (min-width: 992px) {
				display: block;
				}
				
				.team-table-position,
				.team-logo,
				.team-name,
				.team-table-points,
				.team-played-games,
				.team-games-balance,
				.team-small-points-balance,
				.team-small-points-difference {
					position: relative;
					overflow: hidden;
					max-height: 40px;
					padding: 10px 15px;
					font-size: 12px;
					color: #FFF;
					text-align: center;
					text-transform: uppercase;
					line-height: 20px;
				}
				
				.team-table-position,
				.team-logo,
				.team-name {
					background: var(--primary-color);
				}
				
				.team-table-position {
					padding: 10px 0;
					border-right: 1px solid #FFF;
				}
				
				.team-name {
					
					position: relative;
					padding: 10px;
					text-align: left;
					
					&::before, &::after {
						content: "";
						position: absolute;
						top: 0;
						width: 15px;
					}
					
					&::before {
						right: 0;
						border-top: 40px solid transparent;
						border-right: 15px solid #E6E6E6;
					}
					
					&::after {
						right: -15px;
						border-top: 40px solid #E6E6E6;
						border-right: 15px solid transparent;
					}
					
				}
				
				.team-table-points,
				.team-played-games,
				.team-games-balance,
				.team-small-points-balance,
				.team-small-points-difference {
					padding: 10px 0;
					color: var(--primary-color);
				}
				
			}
			
		}
		
	}
	
}
