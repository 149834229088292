.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__best-scores {
					
					&__header {
						
						position: relative;
						padding: 5px 15px;
						font-size: 16px;
						color: var(--white);
						text-transform: uppercase;
						background: var(--primary-color);
						border-bottom: 2px solid var(--primary-special-color);
						line-height: 25px;
						
						@media screen and (min-width: 992px) {
						width: auto;
						height: 55px;
						padding: 10px 15px;
						font-size: 20px;
						line-height: 30px;
						background: var(--primary-color);
						border-bottom: 5px solid var(--primary-special-color);
						}
						
						&__label {}
						
						&__leagues {
							
							position: relative;
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
