@font-face {
	font-family: 'silkaregular';
	src: url('./../@Fonts/silka-regular-webfont.eot');
	src: url('./../@Fonts/silka-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('./../@Fonts/silka-regular-webfont.woff2') format('woff2'),
	url('./../@Fonts/silka-regular-webfont.woff') format('woff'),
	url('./../@Fonts/silka-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	
}

@font-face {
	font-family: 'silkaregular_italic';
	src: url('./../@Fonts/silka-regularitalic-webfont.eot');
	src: url('./../@Fonts/silka-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('./../@Fonts/silka-regularitalic-webfont.woff2') format('woff2'),
	url('./../@Fonts/silka-regularitalic-webfont.woff') format('woff'),
	url('./../@Fonts/silka-regularitalic-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@import "@Assets/@Styles/SCSS/@Variables/index.scss";
@import "@Assets/@Styles/SCSS/@Globals/index.scss";
@import "@Assets/@Styles/SCSS/Elements/index.scss";
