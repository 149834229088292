.service-footer-section {
	
	&__information {
		
		&__list {
			
			&__element {
				
				height: 60px;
				padding: 0 5px;
				color: #FFF;
				text-align: center;
				
				@media screen and (min-width: 992px) {
				height: 80px;
				padding: 0 10px;
				}
				
				.element-name,
				.element-icon,
				.element-value {
					margin: 0;
					text-transform: uppercase;
				}
				
				.element-name {
					
					font-size: 9px;
					line-height: 15px;
					
					@media screen and (min-width: 992px) {
					font-size: 13px;
					line-height: 20px;
					}
					
				}
				
				.element-icon {
					
					margin: 5px 0 10px;
					height: 25px;
					
					@media screen and (min-width: 992px) {
					margin: 5px 0;
					height: 30px;
					}
					
				}
				
				.element-value {
					
					font-size: 12px;
					line-height: 20px;
					
					@media screen and (min-width: 992px) {
					font-size: 13px;
					}
					
				}
				
			}
		
		}
	
	}

}
