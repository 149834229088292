.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					position: relative;
					background: #FFFFFF;
					border: 1px solid var(--default-border-color);
					
				}
				
			}
			
		}
		
	}
	
}
