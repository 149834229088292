.league-tables {
	
	&__group {
		
		&__table {
		
			position: relative;
		
		}
		
	}
	
}
