.game-tables-team-header {
	
	margin-bottom: 10px;
	border-top: 1px solid var(--default-border-color);
	
	@media screen and (min-width: 992px) {
	margin-bottom: 0;
	border-top: none;
	}
	
}
