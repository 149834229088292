.teams-and-players {
	
	&__content {
		
		&__teams-and-players {
		
			position: relative;
			margin-top: 20px;
			
			@media screen and (min-width: 992px) {
			margin-top: 40px;
			}
			
		}
		
	}
	
}
