.home-page {
	
	&__leagues {
		
		&__tables {
			
			&__tables {
				
				&__group {
					
					margin-bottom: 10px;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 20px;
					}
					
				}
				
			}
			
		}
		
	}
	
}
