.player-page {
	
	padding: 0;
	
	@media screen and (min-width: 992px) {
	min-height: 1000px;
	margin: 0;
	background: var(--secondary-color) url("/Assets/Images/Backgrounds/player_details_bg.png") 80% 0 no-repeat;
	}
	
}


#player-details-main-data-component {
	
	padding: 15px 20px;
	background: var(--secondary-color);
	
	@media screen and (min-width: 992px) {
	padding: 20px 40px 30px;
	background: none;
	}
	
	.player-team-logo,
	.player-main-data {}
	
	.player-team-logo {
		
		display: none;
		
		@media screen and (min-width: 992px) {
		display: block;
		margin-bottom: 0;
		padding: 0 30px 0 15px;
		}
		
		a {
			
			display: flex;
			width: 100%;
			padding: 15px;
			text-align: center;
			align-content: center;
			align-items: center;
			justify-content: center;
			
			@media screen and (min-width: 992px) {
			min-height: 100px;
			}
			
			img {
				max-width: 60px;
				max-height: 60px;

				@media screen and (min-width: 992px) {
				max-width: 100%;
				max-height: 120px;
				}
				
			}
			
			&:hover {
				opacity: 0.7;
			}
			
		}
	}
	
	.player-main-data {
		
		margin-bottom: 10px;
		padding-left: 0;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		padding-top: 30px;
		padding-left: 50px;
		}
		
		.player-avatar,
		.player-information {}
		
		.player-avatar {
			
			padding: 0 10px;
			text-align: center;
			@media screen and (min-width: 992px) {
			padding: 0 15px;
			}
			
			.player-avatar-picture {
				
				display: flex;
				justify-content: center;
				align-content: center;
				overflow: hidden;
				width: 80px;
				height: 80px;
				margin: 0 calc(50% - 45px);
				background: var(--white-color);
				border: 5px solid var(--primary-color);
				border-radius: 200px;
				
				@media screen and (min-width: 992px) {
				width: 180px;
				height: 180px;
				margin: 0 calc(50% - 100px);
				border-width: 7px;
				}
				
				img {
					width: auto;
					height: auto;
					@media screen and (min-width: 992px) {
						width: auto;
						height: auto;
					}
				}
				
			}
			
			
			
		}
		
		.player-information {
			
			margin-top: 5px;
			padding-right: 0;
			padding-left: 10px;
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding-left: 35px;
			padding-right: 15px;
			}
			
			> span:not(.player-field-number), > a {
				
				margin-right: 10px;
				padding-right: 10px;
				border-right: 1px solid #FFF;
				
				@media screen and (min-width: 992px) {
				border-width: 2px;
				}
				
				&:last-child {
					margin-right: 0;
					padding-right: 0;
					border-right: none;
				}
				
			}
			
			.player-field-information {
				
				.player-information {
					@media screen and (min-width: 992px) {
					display: flex;
					align-content: flex-start;
					align-items: flex-start;
					justify-content: left;
					padding-left: 35px;
					}
				}
				
				
			}
			
			.player-team-name,
			.player-field-position,
			.player-field-number {
				display: inline-block;
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: 500;
				color: #FFF;
				text-transform: uppercase;
				line-height: 20px;
				@media screen and (min-width: 992px) {
				margin-bottom: 0;
				font-size: 20px;
				line-height: 30px;
				}
			}
			
			.player-team-name,
			.player-field-position {}
			
			.player-team-name {
				@media screen and (min-width: 992px) {}
				&:hover {
					color: var(--primary-special-color);
				}
			}
			
			.player-field-number {
				
				position: relative;
				@media screen and (min-width: 992px) {
				padding-left: 10px;
				padding-right: 90px;
				}
				
				.field-number {
					margin-left: 5px;
					@media screen and (min-width: 992px) {
						display: flex;
						justify-content: center;
						position: absolute;
						top: -25px;
						right: 0;
						width: 80px;
						height: 80px;
						margin-left: 0;
						font-size: 40px;
						line-height: 80px;
						border: 2px solid #FFF;
						border-radius: 80px;
					}
				}
				
			}
			
		}
		
		.player-name {
			
			margin-top: 5px;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			margin-top: 20px;
			}
			
			@media screen and (max-width: 991px) {}
			
			.player-full-name {
				
				padding: 0 10px;
				font-size: 18px;
				font-weight: 500;
				line-height: 30px;
				color: var(--white-color);
				
				@media screen and (min-width: 992px) {
				padding: 0 25px 0 35px;
				font-size: 45px;
				font-weight: 500;
				line-height: 55px;
				border-left: 2px solid #FFF;
				}
				
				span {
					
					margin-right: 5px;
					
					@media screen and (min-width: 992px) {
					display: block;
					margin-right: 0;
					}
					
					&.nick-name {
						color: var(--default-border-color);
					}
					
					&.player-league-status {
						display: block;
						font-size: 14px;
						line-height: 16px;
						color: var(--alert-warning-color);
						@media screen and (min-width: 992px) {
						font-size: 22px;
						line-height: 26px;
						}
					}
					
				}
			}
		}
		
	}
	
}


#player-information-data-component {
	
	padding: 0 0;
	
	@media screen and (min-width: 992px) {
	margin-top: 10px;
	}
	
	.main-section-content {
		justify-content: center;
	}
	
	.message-component {
		margin-right: 0;
		@media screen and (min-width: 992px) {
		border-right: 2px solid var(--white-color);
		}
	}
	
	@media screen and (min-width: 992px) {
	margin-top: 0;
	padding: 0;
	background: var(--secondary-color);
	border-top: none;
	border-bottom: 10px solid var(--primary-color);
	}
	
	.player-information-statistics-component,
	.player-information-description-component {
		background: var(--secondary-color);
		@media screen and (min-width: 992px) {
		background: none;
		}
	}
	
	.player-information-statistics-content,
	.player-information-description-content {
		@media screen and (min-width: 992px) {
		height: 100%;
		align-items: center;
		}
	}
	
	.player-information-component {
		
		padding: 5px;
		text-align: center;
		text-transform: uppercase;
		border: 1px solid #FFF;
		
		@media screen and (min-width: 992px) {
		height: 100%;
		padding: 5px 15px;
		border: none;
		//border-left: 2px solid #FFF;
		border-right: 2px solid #FFF;
		}
		
		@media screen and (min-width: 992px) {
		
		}
		
		.player-information-content {
			flex-direction: column;
			&:first-child {
				padding-bottom: 5px;
			}
		}
		
		.information-label,
		.information-value {
			
			min-height: 20px;
			color: var(--white-color);
			line-height: 20px;
			
			@media screen and (min-width: 992px) {
			min-height: 30px;
			line-height: 30px;
			}
			
		}
		
		.information-label {
			font-size: 10px;
			@media screen and (min-width: 992px) {
			font-size: 14px;
			}
			@media screen and (min-width: 1366px) {
			font-size: 18px;
			}
		}
		
		.information-value {
			font-size: 16px;
			font-weight: 600;
			@media screen and (min-width: 992px) {
			font-size: 20px;
			}
			@media screen and (min-width: 1366px) {
			font-size: 24px;
			}
		}
		
	}
	
	.player-information-statistics-component {
		
		.player-information-component {
			
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			min-height: 56px;
			
			@media screen and (min-width: 992px) {}
			
			&:first-child {
				border-left: 2px solid #FFF;
			}
			
			.player-information-content {
				width: calc(100% + 30px);
				padding-bottom: 0;
			}
			
			.information-label {
				@media screen and (min-width: 992px) {
				margin-bottom: 5px;
				padding-bottom: 15px;
				border-bottom: 2px solid #FFF;
				}
			}
			
			.information-value {
				padding-top: 4px;
				@media screen and (min-width: 992px) {
				padding-top: 10px;
				min-height: 40px;
				}
			}
			
		}
		
	}
	
	.player-information-description-component {
		
		height: 100%;
		
		@media screen and (min-width: 992px) {}
		
		.player-information-component {
			@media screen and (min-width: 992px) {}
		}
		
		.player-information-content {
			
			@media screen and (max-width: 991px) {
			min-height: 50px;
			margin: 0 -5px;
			}
			
			&:first-child {}
			
			&:last-child {
				
				padding-top: 5px;
				
				@media screen and (max-width: 991px) {
				min-height: 51px;
				margin-top: -1px;
				}
				
				@media screen and (min-width: 992px) {
				}
				border-top: 2px solid #FFF;
			}
		}
		
	}
	
}


#player-details-trophies-component {
	
	margin-top: 10px;
	padding-bottom: 20px;
	text-transform: uppercase;
	color: var(--white-color);
	font-size: 13px;
	@media screen and (min-width: 992px) {
		margin-top: 30px;
		padding-bottom: 0;
		font-size: 16px;
	}
	
	
	
}


#player-details-description-component {
	
	margin-top: 10px;
	padding-bottom: 20px;
	@media screen and (min-width: 992px) {
		margin-top: 10px;
		padding-bottom: 30px;
	}
	
	.player-description-header,
	.player-description-content {}
	
	
	
	.player-description-content {
		//margin: 25px 0;
		color: var(--white-color);
		@media screen and (min-width: 992px) {
			//margin: 50px 0;
		}
	}
	
	
	.player-statistics-table {
	
	
	
	}
	
	
	
	.player-trophies-and-titles {
		margin-bottom: 25px;
		@media screen and (min-width: 992px) {
			margin-bottom: 50px;
		}
		.description-element-header,
		.trophies-and-titles-list {}
		.trophies-and-titles-list {
			padding: 5px 10px;
			font-size: 14px;
			font-weight: 500;
			color: var(--white-color);
			text-transform: uppercase;
			@media screen and (min-width: 992px) {
				padding: 10px;
				font-size: 16px;
			}
		}
	}
	
}


.player-games-component {
	
	margin-bottom: 50px;
	
	.player-games-header {
		margin-bottom: 20px;
		h2 {
			font-size: 20px;
			font-weight: 600;
			color: var(--white-color);
			text-transform: uppercase;
		}
	}
	
	.player-games-game {
		
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #FFF;
		
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
		
		.player-game-teams,
		.player-game-statistics {}
		
		.player-game-teams {
			
			.game-teams-points,
			.game-teams-names {
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				line-height: 24px;
			}
			
			.game-teams-points {
				color: var(--white-color);
				span {
					display: block;
				}
			}
			
			.game-teams-names {
				padding-left: 0;
				text-align: left;
				a {
					display: block;
					color: var(--white-color);
				}
			}
			
		}
		
		.player-game-statistics {
			
			@media screen and (min-width: 992px) {
				padding: 0 15px 0 0;
			}
			
			.player-game-statistics-content {
				justify-content: space-between;
			}
			
			.player-game-statistic {
				
				padding: 0 5px;
				min-width: 6%;
				//border-right: 1px solid #FFF;
				font-size: 14px;
				font-weight: 700;
				color: #FFF;
				text-align: center;
				line-height: 24px;
				text-transform: uppercase;
				
				&:last-child {
					border-right: none;
				}
				
				span {
					display: block;
					&.w {
						color: #00FF3A;
					}
					&.l {
						color: #F9172C;
					}
				}
				
			}
			
		}
		
	}
	
}
