.game-result-arena {
	
	margin: 15px 0 15px;
	font-weight: 600;
	line-height: 16px;
	
	@media screen and (min-width: 992px) {
	margin: 0 0 20px;
	}
	
	.game-result-arena-content {
		justify-content: center;
	}
	
	.info-label {
		margin-right: 5px;
		color: #6C757D;
	}
	
}
