.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__played-games {
					
					&__league-games {
						
						&__header {
							
							color: #FFF;
							line-height: 18px;
							background: var(--primary-color);
							
							&__value {
								
								flex-direction: row;
								flex-wrap: wrap;
								min-height: 36px;
								font-size: 11px;
								background: var(--primary-color);
								@media screen and (min-width: 992px) {
								font-size: 12px;
								min-height: 46px;
								height: 100%;
								background: transparent;
								border-right: none!important;
								}
								
								&--game-opponent {
									@media screen and (min-width: 992px) {
									background: var(--secondary-color)!important;
									}
								}
								
								span.statistic-label {
									flex: 0 0 100%;
									width: 100%;
									max-width: 100%;
									text-align: center;
								}
								
								span.separator {
									width: 1px;
									height: 14px;
									margin: 0 7px;
									background: var(--white);
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
