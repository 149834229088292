.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__information {
							
							&__show-content-switcher {
								
								margin-left: 20px;
								flex: 1;
								text-align: right;
								
								&__switcher {
									
									display: inline-flex;
									font-size: 12px;
									cursor: pointer;
									
									&__text {
										margin-right: 5px;
									}
									
									&__icon {
										display: inline-flex;
										align-content: center;
										align-items: center;
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
