.game-tables-team-table-summary {
	
	height: 25px;
	line-height: 25px;
	background: var(--secondary-color);
	
	@media screen and (min-width: 992px) {
	height: 40px;
	line-height: 40px;
	}
	
	.table-row-cell {
		
		font-size: 10px;
		color: #FFF;
		background: var(--secondary-color);
		
		@media screen and (min-width: 992px) {
		height: 100%;
		font-size: 12px;
		line-height: 40px;
		}
		
		a {
			display: block;
		}
		
		&.team-summary-number {}
		
		&.team-summary-name {
			font-weight: 600;
		}
		
		&.team-summary-2pt-throws, &.team-summary-2pt-throws-eff,
		&.team-summary-3pt-throws, &.team-summary-3pt-throws-eff,
		&.team-summary-1pt-throws, &.team-summary-1pt-throws-eff,
		&.team-summary-game-throws, &.team-summary-game-throws-eff {}
		
		&.team-summary-2pt-throws, &.team-summary-3pt-throws,
		&.team-summary-1pt-throws, &.team-summary-game-throws {}
		
		&.team-summary-2pt-throws-eff,
		&.team-summary-3pt-throws-eff,
		&.team-summary-1pt-throws-eff,
		&.team-summary-game-throws-eff {}
		
		&.team-summary-minutes, &.team-summary-1pt-throws-eff {}
		
		.statistic-value {
			font-weight: 600;
		}
		
	}
	
}
