.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__best-scores {
					
					&__list {
						
						@media screen and (min-width: 992px) {}
						
						&__score {
							
							font-size: 8px;
							line-height: 14px;
							background: #FFF;
							border-bottom: 1px solid var(--default-border-color);
							
							@media screen and (min-width: 992px) {
							font-size: 12px;
							line-height: 20px;
							}
							
							@media screen and (min-width: 992px) {
							height: 50px;
							font-size: 14px;
							line-height: 30px;
							border-bottom: none;
							}
							
							&:nth-child(even) {
								background: #EBEBEB;
							}
							
							&:last-child {
								border-bottom: none;
							}
							
							&__content {
								height: 100%;
								@media screen and (min-width: 992px) {
									height: 50px;
								}
							}
							
							&__value {
								
								display: flex;
								min-height: 38px;
								padding: 5px 5px;
								justify-content: center;
								align-content: center;
								align-items: center;
								border-left: 2px solid var(--default-border-color);
								
								@media screen and (min-width: 992px){
								padding: 10px 15px;
								}
								
								&--value-label {
									justify-content: right;
									text-align: right;
								}
								
								&--score-value {
									font-size: 10px;
									color: var(--green-color);
									@media screen and (min-width: 992px) {
									font-size: 14px;
									}
								}
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
