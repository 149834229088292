.free-agent-requests {
	
	&__create-request {
		
		margin-top: 20px;
		
		&__header {
			
			&__label {
				
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: right;
				font-weight: 600;
				cursor: pointer;
				
				&:hover {
					color: var(--primary-special-color);
				}
				
				span {
					margin-right: 5px;
				}
				
			}
			
		}
		
	}
	
}
