.team-page {
	
	&__team-header {
		
		&__team-league {
			
			margin-top: 15px;
			padding: 0;
			color: #FFF;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding: 0 15px;
			}
			
			&__content {}
			
			&__label {
				font-size: 16px;
				color: #FFF;
				@media screen and (min-width: 992px) {}
			}
			
			&__name {
				
				@media screen and (min-width: 992px) {
				margin-top: 5px;
				}
				
				a {
					
					display: block;
					font-size: 16px;
					color: #FFF;
					
					span {
						display: block;
					}
					
					&:hover {
						color: var(--primary-special-color);
					}
					
				}
				
				&__league {
					font-weight: 600;
				}
				
				&__season {
					color: #CBCBCB;
					&:hover {
						color: var(--primary-special-color);
					}
				}
				
			}
			
		}
		
	}
	
}

