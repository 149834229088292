.player-details {
	
	&__details-data {
		
		margin-bottom: 0;
		
		&__statistics-data {
		
			&__content {
				
				margin-bottom: 20px;
				text-transform: uppercase;
				@media screen and (min-width: 992px) {
				margin-bottom: 50px;
				}
				
			}
			
		}
		
	}
	
}
