.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__best-scores {

					&__list {
						
						position: relative;
						color: #222;
						font-weight: 600;
						
						@media screen and (min-width: 992px) {
						margin-top: 10px;
						}
						
						a {
							color: #222;
							&:hover {
								color: var(--primary-special-color);
							}
						}
						
						&__item {
							
							position: relative;
							text-transform: uppercase;
							
							@media screen and (min-width: 992px) {}
							
						}
					
					}
					
				}
				
			}
			
		}
		
	}
	
}
