.home-page {
	
	&__slider {
		
		&__slide {
			
			img {
				margin: auto;
				width: 100%;
			}
			
		}
		
	}
	
}
