.partners-page {
	
	&__partners-list {
		
		&__partner {
			
			position: relative;
			margin: 20px 0;
			padding: 15px 30px;
			background: linear-gradient(90deg, var(--secondary-color) 0%, var(--primary-color) 100%);
			
			@media screen and (min-width: 992px) {
			max-width: calc(33.33333% - 30px);
			flex: 0 0 calc(33.33333% - 30px);
			margin-bottom: 45px;
			margin-right: 45px;
			}
			
			&::after {
				content: "";
				position: absolute;
				bottom: 15px;
				right: 15px;
				width: 50px;
				height: 50px;
				background: url("/Assets/Images/plka_white_logo.png") 100% 100% no-repeat;
				background-size: 100% auto;
				opacity: 0.5;
			}
			
			&:nth-child(3n + 3) {
				@media screen and (min-width: 992px) {
				margin-right: 0;
				}
			}
			
			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
			
			&__header,
			&__content,
			&__footer {
				color: var(--default-font-bright-color);
				a{
					color: var(--default-font-bright-color);
					&:hover {
						color: var(--primary-special-color);
					}
				}
			}
			
			&__header {
				
				margin-bottom: 30px;
				@media screen and (min-width: 992px) {
				margin-bottom: 15px;
				}
				
				.partner-title,
				.partner-logo {
					text-align: center;
				}
				
				.partner-title {
					margin-bottom: 15px;
					font-size: 16px;
					font-weight: 600;
					text-transform: uppercase;
					@media screen and (min-width: 992px) {
					margin-bottom: 20px;
					font-size: 20px;
					}
				}
				
				.partner-logo {
					height: 80px;
					padding: 15px;
					background: var(--white-color);
					img {
						height: 80px;
					}
				}
				
			}
			
			&__content {
				.partner-description {
					font-size: 11px;
					line-height: 18px;
					@media screen and (min-width: 992px) {
					font-size: 13px;
					}
					p {
						margin-bottom: 0;
					}
				}
			}
			
			&__footer {
				
				margin-top: 20px;
				@media screen and (min-width: 992px) {
				margin-top: 50px;
				}
				//font-weight: 600;
				
				.partner-www {
					a, span {}
					span {
						margin-right: 5px;
					}
				}
				
			}
		
		}
		
	}
	
}
