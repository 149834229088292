.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				margin-top: 20px;
				
				@media screen and (min-width: 992px) {
				margin-top: 0;
				}
				
				&__header {
					margin-bottom: 10px;
					padding: 0;
					font-size: 16px;
					font-weight: 600;
				}
				
			}
			
		}
		
	}
	
}
