.game-tables-team-table-legend {
	
	margin-top: 50px;
	
	@media screen and (min-width: 992px) {
	margin-top: 50px;
	}
	
	.game-tables-team-table-legend-content {
		justify-content: center;
	}
	
	.legend-label, .legend-element {
		font-size: 10px;
		@media screen and (min-width: 992px) {
			font-size: 12px;
			font-weight: 600;
		}
	}
	
	.legend-label {
		display: none;
		padding: 0;
		margin: 0 5px;
		line-height: 20px;
		@media screen and (min-width: 992px) {
			display: block;
			margin: 0 15px;
			padding: 0 15px;
			line-height: 24px;
		}
	}
	
	.legend-element {
		
		position: relative;
		margin: 0 5px;
		padding: 0 10px 0 15px;
		color: #FFF;
		line-height: 18px;
		background: var(--primary-color);
		border-bottom: 2px solid var(--primary-color);
		
		@media screen and (min-width: 992px) {
			margin: 0 25px;
			padding: 0 25px 0 30px;
			line-height: 20px;
			border-bottom-width: 4px;
		}
		
		&::before, &::after {
			content: "";
			position: absolute;
			top: 0;
			width: 5px;
			height: 20px;
			@media screen and (min-width: 992px) {
				width: 5px;
				height: 24px;
			}
		}
		
		&::before {
			right: 0;
			border-top: 20px solid transparent;
			border-right: 5px solid var(--primary-color);
			@media screen and (min-width: 992px) {
				border-top-width: 24px;
				border-right-width: 10px;
			}
		}
		
		&::after {
			right: -5px;
			border-top: 20px solid var(--primary-color);
			border-right: 5px solid transparent;
			@media screen and (min-width: 992px) {
				right: -10px;
				border-top-width: 24px;
				border-right-width: 10px;
			}
		}
		
		span {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 5px;
				height: 18px;
				border-top: 18px solid #FFF;
				border-right: 5px solid var(--primary-color);
				@media screen and (min-width: 992px) {
					border-top-width: 20px;
					border-right-width: 10px;
				}
			}
		}
		
	}
	
	.fouls-not-sport {
		border-color: #993B99;
		&::before {
			border-right-color: #993B99;
		}
		&::after {
			border-top-color: #993B99;
		}
	}
	
	.fouls-technical {
		border-color: var(--primary-special-color);
		&::before {
			border-right-color: var(--primary-special-color);
		}
		&::after {
			border-top-color: var(--primary-special-color);
		}
	}
	
	.fouls-disqualification {
		border-color: #E2000F;
		&::before {
			border-right-color: #E2000F;
		}
		&::after {
			border-top-color: #E2000F;
		}
	}
	
}
