.game-result-page {
	
	&__admin {
		
		display: none;
		margin-bottom: 50px;
		padding: 0;
		font-size: 14px;
		font-weight: 600;
		text-align: right;
		
		@media screen and (min-width: 992px) {
		display: block;
		}
		
		a {
			padding: 5px 15px;
			border: 2px solid var(--primary-color);
		}
		
	}
	
}
