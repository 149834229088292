.player-details {
	
	&__details-data {
		
		position: relative;
		padding: 15px 10px;
		background: var(--secondary-color);
		
		@media screen and (min-width: 992px) {
		padding: 50px 30px;
		}
		
		.system-message-component {
			margin: 0 0 50px;
		}
		
	}
	
}
