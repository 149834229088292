.service-footer-section {
	
	&__information {
		
		padding: 0;
		
		@media screen and (min-width: 992px) {
		height: 80px;
		margin: 20px 0;
		padding: 0 15px;
		}
		
		&__list {
			
			list-style: none;
			margin: 0;
			padding: 0;
			justify-content: center;
			
		}
		
	}

}
