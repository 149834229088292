.league-tables {
	
	&__group {
		
		margin-bottom: 20px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
	}
	
}
