.teams-and-players {
	
	&__content {
		
		&__teams-and-players {
			
			&__teams {
				
				&__list {
				
					margin-top: 30px;
					padding-top: 20px;
					border-top: 1px solid var(--default-border-color);
					
					@media screen and (min-width: 992px) {}
					
					&__team {
						
						margin-bottom: 10px;
						padding: 10px;
						text-align: center;
						
						@media screen and (min-width: 992px) {
						margin-bottom: 30px;
						padding: 10px 25px;
						}
						
						&__logo {
							
							display: flex;
							align-items: center;
							justify-content: center;
							height: 80px;
							margin-bottom: 10px;
							padding: 20px;
							
							@media screen and (min-width: 992px) {
							height: 130px;
							padding: 15px;
							}
							
							a {
								
								display: block;
								
								&:hover {
									opacity: 0.7;
								}
								
								img {
									max-width: 100%;
									max-height: 100px;
								}
								
							}
							
						}
						
					}
					
					
				}
				
			}
			
		}
		
	}
	
}
