.header-languages-switcher {
	
	margin-top: 15px;
	padding-right: 15px;
	
	@media screen and (min-width: 992px) {
	position: absolute;
	top: -15px;
	right: 10px;
	margin-top: 0;
	z-index: 1000;
	}
	
	.header-languages-switcher-content {
		justify-content: right;
	}
	
	.form-control {
		max-width: 60px;
		height: 25px;
		padding: 0 5px;
	}
	
}
