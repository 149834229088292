.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__leagues-statistics {
					
					&__statistics-table {
						
						&__leagues-list {
						
							&__header {
								
								color: #FFF;
								background: var(--primary-color);
								
								@media screen and (min-width: 992px) {
								padding: 0;
								}
								
								&__value {
									
									height: 40px;
									background: var(--primary-color);
									@media screen and (min-width: 992px) {
									height: 100%;
									padding: 5px 0;
									background: transparent;
									line-height: 20px!important;
									border-right: none!important;
									}
									
									&--team-name {
										display: flex;
										padding: 15px 0;
										justify-content: center;
										align-items: center;
										align-content: center;
										@media screen and (min-width: 992px) {
										height: 50px!important;
										background: var(--secondary-color)!important;
										}
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
