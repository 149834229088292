.teams-and-players {
	
	&__content {
		
		&__seasons-and-leagues {
			
			&__seasons {
				
				&__seasons-list {
					
					&__selected-season {
						
						display: flex;
						position: relative;
						align-items: center;
						align-content: center;
						height: 45px;
						padding: 5px 15px;
						font-size: 14px;
						color: #FFF;
						line-height: 30px;
						background: var(--secondary-color);
						border-bottom: 5px solid var(--primary-special-color);
						
						@media screen and (min-width: 992px) {
						font-size: 18px;
						height: 55px;
						padding: 10px 45px 10px 15px;
						}
						
						&__season-label {
							margin-right: 10px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						
						&::before, &::after {
							position: absolute;
							top: 0;
							width: 10px;
							height: 45px;
							content: "";
							@media screen and (min-width: 992px) {
							height: 55px;
							}
						}
						
						&::before {
							@media screen and (min-width: 992px) {
							right: -10px;
							border-top: 55px solid var(--primary-special-color);
							border-right: 10px solid transparent;
							}
						}
						
						&::after {
							@media screen and (min-width: 992px) {
							right: 0;
							border-top: 55px solid transparent;
							border-right: 10px solid var(--primary-special-color);
							}
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
