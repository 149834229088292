.team-page {
	
	&__team-header {
		
		position: relative;
		//margin-bottom: 10px;
		padding: 15px 15px;
		background: var(--secondary-color);
		border-bottom: 3px solid #FFF;
		
		@media screen and (min-width: 992px) {
		width: calc(100% + 30px);
		margin: 0 -15px;
		padding: 30px 15px;
		}
		
		.main-section-content {
			position: relative;
		}
		
	}
	
}
