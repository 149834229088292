.team-details {
	
	&__team-players {
		
		&__list {
			
			&__team-name {
				
				margin-bottom: 10px;
				padding: 0;
				font-size: 14px;
				font-weight: 600;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 20px;
				padding: 0 15px;
				font-size: 16px;
				}
				
				&__short-name {
					margin-left: 5px;
				}
				
			}
			
		}
		
	}
	
}
