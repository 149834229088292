.leagues-schedules {
	
	&__leagues-and-rounds-switcher {
	
		&__leagues-switcher {
			
			height: 40px;
			background: var(--primary-color);
			
			@media screen and (min-width: 992px) {
			height: 50px;
			}
			
			&__league {
			
				&--selected-league {
					
					
					overflow: hidden;
					height: 40px;
					padding-right: 40px;
					color: #FFF;
					background: var(--primary-color);
					
					@media screen and (min-width: 992px) {
					height: 50px;
					background: var(--secondary-color);
					}
					
					&__content {
						height: 100%;
						padding: 0 15px;
						align-content: center;
						align-items: center;
					}
					
					&__label, &__icon {
						display: inline-flex;
					}
					
					&__label {
						
						width: 100%;
						font-size: 12px;
						font-weight: 600;
						text-transform: uppercase;
						line-height: 15px;
						
						@media screen and (min-width: 992px) {
						font-size: 14px;
						line-height: 20px;
						}
						
					}
					
					&__icon {
						
						position: absolute;
						top: 12px;
						right: 15px;
						cursor: pointer;
						
						@media screen and (min-width: 992px) {
						top: 17px;
						}
						
						svg {
							width: 16px;
							height: 16px;
						}
						
					}
					
				}
			
			}
			
			&__leagues-list {
				
				position: absolute;
				top: 40px;
				background: #FFF;
				z-index: 10000;
				border: 1px solid var(--primary-color);
				
				@media screen and (min-width: 992px) {
				top: 50px;
				}
				
				&__league {
					
					position: relative;
					font-weight: 600;
					text-transform: uppercase;
					border-bottom: 1px solid var(--primary-color);
					
					&:last-child {
						border-bottom: none;
					}
					
					span {
						
						display: block;
						width: 100%;
						padding: 5px 15px;
						line-height: 20px;
						cursor: pointer;
						
						@media screen and (min-width: 992px) {
						line-height: 24px;
						}
						
					}
					
				}
				
			}
		
		}
	
	}
	
}
