.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			&__quarter {
				
				&__events {
					
					&__event {
					
						background: #FFF;
						
						@media screen and (min-width: 992px) {}
						
						&:nth-child(odd) {
							background: var(--default-border-color);
						}
						
						&__event-content {
							
							&__content {
								height: 100%;
							}
							
							&__order-nr,
							&__event-player,
							&__points-and-time {
								height: 100%;
							}
							
							&__order-nr {
								display: none;
								font-weight: 600;
								text-align: center;
								border-bottom: 1px solid #E1E4E4;
								
								@media screen and (min-width: 768px) {
								display: block;
								}
								
								@media screen and (min-width: 992px) {
								display: flex;
								align-content: center;
								align-items: center;
								justify-content: center;
								padding: 5px 5px;
								border-right: 2px solid #E1E4E4;
								border-bottom: none;
								}
								
							}
							
							&__event-player {
								
								padding: 5px 10px;
								font-size: 10px;
								text-transform: none;
								line-height: 16px;
								
								@media screen and (min-width: 992px) {
								padding: 10px 25px;
								font-size: 12px;
								line-height: 24px;
								}
								
								&__content {
									justify-content: center;
									@media screen and (min-width: 992px) {
									height: 100%;
									align-items: center;
									align-content: center;
									justify-content: left;
									}
								}
								
								.event-player-name {
									text-transform: uppercase;
								}
								
							}
							
							.event-icon {
								
								max-width: 20px;
								max-height: 16px;
								margin-right: 5px;
								
								@media screen and (min-width: 992px) {
								max-width: 35px;
								max-height: 25px;
								}
								
							}
							
							.event-label {
								
								display: block;
								width: 100%;
								text-align: center;
								
								@media screen and (min-width: 992px) {
								display: inline;
								width: auto;
								text-align: left;
								}
								
								&.player-out {
									font-weight: 600;
									//color: #E2000F;
								}
								
							}
							
							.event-number {
								margin-left: 5px;
							}
							
							.event-description {
								margin-left: 5px;
							}
							
							&--substitution-event {
								
								
								.substitution-player-out,
								.event-label {
									margin-right: 5px;
								}
								
								.event-label {
								
								}
								
								.event-label {
									color: #000;
								}
								
							}
							
							&--foul-event {
								
								&.technical {
									background: var(--primary-special-color);
								}
								
								&.unsportsmanlike {
									background: #993b99;
								}
								
								&.disqualification {
									background: #E2000F;
								}
								
								&.player-out {
									//background: #E2000F;
									.fouling-player {
										color: #E2000F;
									}
								}
								
								&.technical, &.unsportsmanlike, &.disqualification {
									
									color: #FFF;
									
									a {
										color: #FFF;
									}
									
								}
								
								.event-label {
									@media screen and (min-width: 992px) {
									margin-left: 5px;
									}
								}
								
							}
							
							&--throw-event {
								
								.event-player-name {
									margin-right: 5px;
								}
								
								.event-label {
									color: #000;
								}
								
							}
							
							&--points-event {
								
								font-weight: 600;
								
								.throwing-player,
								.assisting-player {
									height: auto;
								}
								
								.event-player-name {
									margin-right: 5px;
								}
								
								.event-label {
									color: #000;
								}
								
								.assisting-player {
									
									//color: #666;
									
									@media screen and (min-width: 992px) {
									padding-left: 30px;
									}
									
									.event-name {
										
										display: block;
										width: 100%;
										text-align: center;
										margin-right: 5px;
										
										@media screen and (min-width: 992px) {
										display: inline;
										width: auto;
										text-align: left;
										}
										
									}
									
									.event-number {
										margin-left: 0;
									}
									
								}
								
							}
							
							&--rebound-event {
								
								.event-label {
									margin-left: 5px;
								}
								
							}
							
							&--block-event {
								
								.event-label {
									margin-left: 5px;
								}
								
							}
							
							&--default-event {
								
								.event-label {
									margin-left: 5px;
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
