.home-page-component {
	
	padding: 0;
	@media screen and (min-width: 992px) {}
	
	.home-page-news-component {
		
		padding: 0;
		@media screen and (min-width: 992px) {
		margin-bottom: 50px;
		padding: 20px 15px;
		background: var(--primary-color);
		}
		
		.home-page-active-article {
			
			margin-top: 20px;
			@media screen and (min-width: 992px) {
			margin-top: 0;
			margin-bottom: 0;
			}
			
			.home-page-active-article-content {
				align-content: center;
				align-items: center;
			}
			
			.active-article-texts {
				
				margin-bottom: 10px;
				text-align: center;
				@media screen and (min-width: 992px) {
					margin-bottom: 0;
					padding-right: 30px;
					text-align: left;
				}
				
				a {
					display: block;
					@media screen and (min-width: 992px) {
						color: #FFF;
						&:hover {
							color: var(--primary-special-color);
						}
					}
				}
				
				.active-article-title {
					font-size: 16px;
					font-weight: 600;
					text-transform: uppercase;
					@media screen and (min-width: 992px) {
						margin-bottom: 40px;
						font-size: 22px;
						line-height: 26px;
					}
				}
				
				.active-article-short-content {
					display: none;
					@media screen and (min-width: 992px) {
						display: block;
						margin-bottom: 40px;
						font-size: 15px;
						line-height: 24px;
					}
				}
				
				.active-article-read-more {
					display: none;
					@media screen and (min-width: 992px) {
						display: block;
					}
					a {
						display: inline-block;
						padding: 10px 20px;
						font-weight: 600;
						text-transform: uppercase;
						border: 2px solid #FFF;
						border-radius: 8px;
					}
				}
				
			}
			
			.active-article-image {
				overflow: hidden;
				@media screen and (min-width: 992px) {
					//border-top-left-radius: 100px;
					border-bottom-left-radius: 150px;
					border-bottom-right-radius: 150px;
				}
				a {
					display: block;
					&:hover {
						opacity: 0.7;
					}
				}
			}
			
		}
		
	}
	
	
	.home-page-articles-list {
		
		@media screen and (min-width: 992px) {
		margin-top: 20px;
		}
		
		.home-page-article {
			
			padding: 20px 10px;
			text-align: center;
			background: #FFF;
			border-bottom: 1px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			padding: 0 10px;
			background: transparent;
			border-right: 2px solid #FFF;
			border-bottom: none;
			}
			
			&:first-child {
				border-top: 1px solid var(--default-border-color);
				@media screen and (min-width: 992px) {
				border-top: none;
				}
			}
			
			&:last-child {
				border-right: none;
			}
			
			span {
				display: block;
				font-size: 16px;
				text-transform: uppercase;
				cursor: pointer;
				@media screen and (min-width: 992px) {
					color: #FFF;
					&:hover {
						color: var(--primary-special-color);
					}
				}
				&.active {
					color: var(--primary-special-color);
				}
			}
		}
		
	}
	
}
