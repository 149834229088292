.game-result-details {
	
	&__progress {
		
		&__footer {
			
			padding: 15px;
			font-weight: 600;
			color: #FFF;
			background: var(--primary-color);
			
			&__content {
				justify-content: center;
			}
			
		}
		
	}
	
}
