.article-detail {

	&__content {
		
		&__header {
			
			margin-bottom: 15px;
			@media screen and (min-width: 992px) {
			margin-bottom: 20px;
			}
			
		}
		
	}

}
