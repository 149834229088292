.game-result-details {
	
	&__throws {
		
		position: relative;
		padding: 0 20px;
		
		@media screen and (min-width: 992px) {
		margin-top: 30px;
		padding: 0 15px;
		}
		
		.game-information-throws-team {
			
			position: relative;
			margin-bottom: 25px;
			@media screen and (min-width: 992px) {
			padding-bottom: 50px;
			}
			
			&.throws-home-team {
				@media screen and (min-width: 992px) {
				padding-right: 30px;
				}
			}
			
			&.throws-away-team {
				@media screen and (min-width: 992px) {
				padding-left: 30px;
				}
			}
			
			.throws-team-name {
				
				position: relative;
				margin-bottom: 10px;
				padding: 5px 10px;
				font-size: 18px;
				line-height: 30px;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 20px;
				padding: 10px 25px;
				font-size: 22px;
				font-weight: 600;
				line-height: 20px;
				}
				
				&.home-team-name {
					background: var(--secondary-color);
				}
				
				&.away-team-name {
					background: var(--secondary-color);
				}
				
				a {
					
					display: block;
					color: #FFF;
					
					&:hover {
						opacity: 0.7;
					}
					
				}
				
				span.bg {
					
					display: none;
					position: absolute;
					top: 0;
					right: 0;
					width: 20px;
					height: 40px;
					border-top: 40px solid transparent;
					border-right: 20px solid #F8F8F8;
					
					@media screen and (min-width: 992px) {
					display: block;
					}
					
				}
				
			}
			
			.throws-team-players {
				
				margin-bottom: 10px;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 0;
				}
				
				.throws-team-players-content {
				@media screen and (min-width: 992px) {
				margin-right: -5px;
				}
				}
				
				.team-players-header {
					
					padding: 0 0 5px;
					margin-bottom: 10px;
					font-size: 14px;
					font-weight: 600;
					color: #1A1A18;
					border-bottom: 2px solid var(--secondary-color);
					
					@media screen and (min-width: 992px) {
					margin-bottom: 20px;
					font-size: 18px;
					border-bottom-width: 10px;
					}
					
				}
				
				.team-players-player {
					
					margin-bottom: 5px;
					padding: 0;
					
					&:last-child {
						margin-bottom: 0;
					}
					
					.checkbox,
					.player-name {
						display: inline-block;
						vertical-align: top;
						cursor: pointer;
					}
					
					.checkbox {
						
						width: 14px;
						height: 14px;
						margin-right: 5px;
						border: 1px solid #333;
						border-radius: 5px;
						
						@media screen and (min-width: 992px) {
						width: 16px;
						height: 16px;
						margin-top: 2px;
						margin-right: 10px;
						}
						
						&.checked {
							background: #CCC;
						}
						
					}
					
					.player-name {
						
						max-width: 85%;
						font-size: 10px;
						color: #333;
						line-height: 14px;
						
						@media screen and (min-width: 992px) {
						max-width: none;
						font-size: 12px;
						line-height: 18px;
						}
						
						&.selected {
							font-weight: 600;
						}
						
					}
					
				}
				
			}
			
			.throws-team-chart {
				min-height: 440px;
			}
			
			.team-throws-chart {
				
				position: relative;
				width: 380px;
				height: 440px;
				padding: 50px 0 60px 0;
				background-color: #EBEBEB;
				background-position: center 20px;
				background-repeat: no-repeat;
				background-size: 350px 360px;
				
				.team-throws-legend {
					
					margin-top: 5px;
					
					@media screen and (min-width: 992px) {
					position: absolute;
					bottom: 15px;
					right: 0;
					margin-top: 0;
					text-align: right;
					}
					
					.accurate-throws,
					.not-accurate-throws {
						
						position: relative;
						padding-left: 20px;
						font-weight: 600;
						line-height: 20px;
						
						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 14px;
							height: 14px;
							border-radius: 20px;
						}
					}
					
					.accurate-throws {
						margin-right: 10px;
						&::before {
							background: #00953F;
						}
					}
					
					.not-accurate-throws {
						&::before {
							background: #E2000F;
						}
					}
					
				}
				
			}
			
			.throws-team-select-all-button {
				
				margin-top: 10px;
				padding: 0;
				
				@media screen and (min-width: 992px) {
				position: absolute;
				bottom: 0;
				left: 0;
				margin-top: 0;
				padding-left: 0;
				padding-right: 30px;
				}
				
				.btn {
					
					position: relative;
					width: 100%;
					height: 35px;
					padding: 5px 35px 5px 15px;
					font-size: 12px;
					
					&::after {
						
						display: none;
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						width: 20px;
						height: 35px;
						border-top: 35px solid transparent;
						border-right: 20px solid #F8F8F8;
						
						@media screen and (min-width: 992px) {
						display: block;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
