.team-details {
	
	&__team-information {
		
		margin-bottom: 0;
		
		@media screen and (min-width: 992px) {
		margin-top: 20px;
		}
		
		&__content {
			padding: 0 5px;
		}
		
	}
	
}
