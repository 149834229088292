.article-detail {
	
	&__content {
		
		&__header {
			
			&__short-text {
				
				margin-top: 15px;
				font-size: 12px;
				
				@media screen and (min-width: 992px) {
				margin-top: 0;
				font-size: 14px;
				line-height: 30px;
				}
				
				&__content {
					margin: 0;
				}
				
			}
			
		}
		
	}
	
}
