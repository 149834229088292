.league-tables {
	
	&__group {
		
		&__header {
			
			margin-bottom: 10px;
			padding: 0;
			font-weight: 600;
			text-transform: uppercase;
			
		}
		
	}
	
}
