.game-result-details {
	
	&__game-progress {
		
		&__quarters {
			
			&__quarter {
				
				&__events {
					
					&__event {
						
						&__order-nr {
							
							display: none;
							padding: 0 5px!important;
							font-size: 10px;
							font-weight: 600;
							color: #1A1A18;
							text-align: center;
							border-top: 1px solid #E1E4E4;
							border-bottom: 1px solid #E1E4E4;
							
							@media screen and (min-width: 992px) {
							display: block;
							padding: 15px 15px!important;
							font-size: 14px;
							border-top: none;
							border-bottom: none;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
