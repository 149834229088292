.files-for-download {

	&__files-groups {
		
		&__file-group {
			
			&__file {
				
				flex: 0 0 calc(100% - 30px);
				margin: 0 15px;
				padding: 5px 15px;
				background: #FFF;
				
				&:nth-child(odd) {
					background: var(--default-border-color);
				}
				
				.file-download-link {
					
					display: inline-flex;
					font-weight: 600;
					align-content: center;
					align-items: center;
					
					.file-name {
						margin-right: 5px;
					}
					
					.file-icon {}
					
				}
				
				.file-description {
					display: block;
					font-size: 11px;
					color: #555;
					line-height: 15px;
				}
				
			}
			
		}
		
	}
	
}
