.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__played-games {
					
					&__league-games {
						
						&__game {
							
							font-weight: 600;
							background: #FFF;
							@media screen and (min-width: 992px) {
							border: none;
							}
							
							&:nth-child(odd) {
								@media screen and (min-width: 992px) {
								background: #E6E6E6;
								}
							}
							
							&__value {
								
								overflow: hidden;
								display: flex;
								flex-direction: column;
								min-height: 40px;
								font-size: 10px;
								background: #FFF;
								border-right: 1px solid var(--default-border-color);
								border-bottom: 1px solid var(--default-border-color);
								
								@media screen and (min-width: 992px) {
								min-height: 46px;
								height: 100%;
								padding: 5px 5px;
								font-size: 12px;
								background: transparent;
								border-bottom: none;
								}
								
								&:last-child {
									border-right: none;
								}
								
								span.separator {
									width: 1px;
									height: 20px;
									margin: 0 5px;
									background: var(--primary-color);
								}
								
								span.separator {
									background: var(--primary-color);
								}
								
								&--game-date {}
								
								&--game-opponent {}
								
								&--game-result {
									
									flex-direction: column;
									line-height: 20px;
									
									&.victory {
										color: var(--green-color);
									}
									
									&.lost {
										color: var(--red-color);
									}
									
								}
								
								&--start-5 {
									color: var(--red-color);
									&.first-squad {
										color: var(--green-color);
									}
								}
								
								&--throws-2pt,
								&--throws-3pt,
								&--throws-game,
								&--throws-1pt,
								&--rebounds, {
									flex-direction: row;
								}
								
							}
						
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
