.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__players-list {
					
					&__players {
						
						&__player {
							
							overflow: hidden;
							padding: 5px 15px;
							
							@media screen and (min-width: 992px) {
							height: 40px;
							}
							
							&:nth-child(odd) {
								background: #EBEBEB;
							}
							
							&__content {
								align-items: center;
								align-content: center;
							}
							
							&__player-profile-image {
								
								a {
									
									display: block;
									
									&:hover {
										opacity: 0.7;
									}
									
									img {
										max-height: 30px;
									}
									
								}
								
							}
							
							&__player-name,
							&__team-status,
							&__system-status {
								line-height: 15px;
							}
							
							&__player-name {
								font-weight: 600;
							}
							
							&__team-status, &__system-status {
								font-size: 11px;
								text-align: center;
							}
							
							&__team-status {
								&.status-1 {
									color: #1C7430;
								}
							}
							
							&__system-status {
								
								padding: 0;
								
								&__activated-status,
								&__formals-accepted-status {
									
									display: flex;
									align-content: center;
									align-items: center;
									justify-content: center;
									
									svg {
										margin-left: 5px;
									}
									
								}
								
								&__activated-status.activated,
								&__formals-accepted-status.accepted {
									color: #1C7430;
								}
								
								&__activated-status.not-activated,
								&__formals-accepted-status.not-accepted {
									color: #E2000F;
								}
								
							}
							
							&__shirt-number {
								.form-control {
									text-align: center;
								}
							}
							
							&__edit-player, &__remove-player, &__send-message-to-player {
								
								display: inline-flex;
								align-items: center;
								align-content: center;
								justify-content: right;
								
								.action-item-button {
									
									display: inline-flex;
									margin-right: 10px;
									cursor: pointer;
									
									&:last-child {
										margin-right: 0;
									}
									
									svg {
										width: 16px;
										height: 16px;
									}
									
									&.save-player-data, &.send-email.active {
										color: #1C7430;
									}
									
									&.cancel-edit-player-data {
										color: #E2000F;
									}
									
									&.remove-player {
										&.active {
											color: #E2000F;
										}
									}
									
									&.cancel-remove-player {
										color: #DA5E15;
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
