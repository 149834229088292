.service-sponsors {
	
	position: relative;
	padding: 20px 0;
	border-top: 1px solid var(--default-border-color);
	
	@media screen and (min-width: 992px) {
	position: absolute;
	bottom: 240px;
	padding: 10px 0;
	}
	
	&__carousel {
		
		width: 100%;
		height: 80px;
		padding: 10px 0;
		@media screen and (min-width: 992px) {
		height: 120px;
		padding: 20px 0;
		}
		
		.slick-list, .slick-track, .slick-slide {
			height: 60px;
			max-height: 60px;
			@media screen and (min-width: 992px) {
			height: 80px;
			max-height: 80px;
			}
		}
		
	}
	
}

