.teams-and-players {
	
	&__content {
		
		&__teams-and-players {
			
			&__players {
				
				&__search-bar {
					
					&__label {
						
						font-size: 14px;
						line-height: 30px;
						
						@media screen and (min-width: 992px) {
						font-size: 18px;
						text-align: right;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
