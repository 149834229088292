.team-header-name {
	
	position: relative;
	padding: 10px 15px;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	
	@media screen and (max-width: 991px) {
	//background: var(--secondary-color)!important;
		border-bottom-right-radius: 25px;
	}
	
	@media screen and (min-width: 992px) {
	height: 50px;
	padding: 0 15px 10px;
	font-size: 20px;
	line-height: 40px;
	background: #DA5E15;
	}
	
	.team-header-name-content {
		height: 100%;
		text-align: left;
		align-items: center;
		@media screen and (min-width: 992px) {
		position: relative;
		height: 40px;
		text-align: center;
		border-right: 30px solid transparent!important;
		border-top: 40px solid var(--secondary-color)!important;
		}
	}
	
	.team-name {
		@media screen and (min-width: 992px) {
		position: absolute;
		top: -40px;
		left: 0;
		}
	}
	
	a {
		
		display: block;
		color: #FFF;
		
		@media screen and (min-width: 992px) {}
		
		&:hover {
			color: var(--primary-special-color);
		}
	}
	
	span.bg {
		
		display: none;
		
		@media screen and (min-width: 992px) {
		display: block;
		position: absolute;
		top: -40px;
		right: -65px;
		width: 35px;
		height: 50px;
		border-top-width: 50px;
		border-top-style: solid;
		border-right-width: 35px;
		border-right-style: solid;
		border-right-color: transparent!important;
		}
		
	}
	
}
