.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__played-games {
					
					&__header {
						
						&__leagues {
							
							&__list {
								
								position: absolute;
								top: 32px;
								width: auto;
								max-width: none;
								white-space: nowrap;
								background: var(--secondary-color);
								border: 1px solid #FFF;
								z-index: 1000;
								
								@media screen and (min-width: 992px) {
								top: 45px;
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
