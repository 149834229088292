.teams-and-players {
	
	&__content {
		
		&__teams-and-players {
			
			&__players {
				
				&__list {
					
					&__letter {
						
						&__players {
							
							&__player {
								margin-bottom: 0;
								padding-left: 0;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
