.teams-and-players {
	
	&__content {
		
		&__teams-and-players {
			
			&__players {
				
				&__list {
				
					margin-top: 20px;
					padding-top: 20px;
					border-top: 1px solid var(--default-border-color);
					
				}
				
			}
			
		}
		
	}
	
}
