.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__best-scores {
					
					&__header {
						
						&__leagues {
							
							&__list {
								
								&__league {
									
									padding: 5px 15px;
									font-size: 16px;
									line-height: 20px;
									border-bottom: 1px solid #FFF;
									cursor: pointer;
									
									&:hover {
										color: var(--primary-special-color);
									}
									
									&:last-child {
										border-bottom: none;
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
