.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__played-games {
					
					&__league-games {
						
						position: relative;
						margin-bottom: 25px;
						font-weight: 500;
						text-transform: uppercase;
						
						@media screen and (min-width: 992px) {
						margin-bottom: 50px;
						}
						
						&__content {
							overflow: hidden;
							overflow-x: scroll;
							@media screen and (min-width: 992px) {
							overflow-x: hidden;
							}
						}
						
						&__row {
							
							position: static;
							width: 1160px;
							max-width: 1160px;
							flex: 0 0 1160px;
							
							@media screen and (min-width: 992px) {
							position: relative;
							width: 100%;
							max-width: 100%;
							flex: 0 0 100%;
							padding: 0;
							}
							
							&__content {
								
								justify-content: left;
								align-items: center;
								align-content: center;
								padding-left: 250px;
								
								@media screen and (min-width: 992px) {
								justify-content: space-between;
								width: 100%;
								margin: 0;
								padding-left: 0;
								}
								
							}
							
							&__value {
								
								display: flex;
								align-items: center;
								align-content: center;
								justify-content: center;
								overflow: hidden;
								text-align: center;
								padding: 0 5px;
								min-width: 24px;
								max-width: 24px;
								line-height: 14px;
								border-right: 1px solid var(--default-border-color);
								
								@media screen and (min-width: 992px) {
								flex: 0 0 2.5%;
								min-width: 2.5%;
								max-width: 2.5%;
								height: 100%;
								line-height: 18px;
								background: transparent;
								border-right-width: 2px;
								}
								
								&:last-child {
									border-right: none;
								}
								
								&--game-date,
								&--game-opponent,
								&--game-result {
									position: absolute;
									z-index: 1;
									@media screen and (min-width: 992px) {
									position: static;
									}
								}
								
								&--game-date {
									left: 0;
									flex: 0 0 80px;
									width: 80px;
									max-width: 80px;
									@media screen and (min-width: 992px) {
									flex: 0 0 7.5%;
									width: 7.5%;
									max-width: 7.5%;
									}
								}
								
								&--game-opponent {
									left: 80px;
									flex: 0 0 110px;
									width: 110px;
									max-width: 110px;
									@media screen and (min-width: 992px) {
									flex: 0 0 22%;
									width: 22%;
									max-width: 22%;
									}
								}
								
								&--game-result {
									left: 190px;
									flex: 0 0 60px;
									width: 60px;
									max-width: 60px;
									@media screen and (min-width: 992px) {
									flex: 0 0 5%;
									width: 5%;
									max-width: 5%;
									}
								}
								
								&--start-5 {
									flex: 0 0 40px;
									width: 40px;
									max-width: 40px;
									@media screen and (min-width: 992px) {
									flex: 0 0 2.5%;
									width: 2.5%;
									max-width: 2.5%;
									}
								}
								
								&--minutes {
									flex: 0 0 50px;
									width: 50px;
									max-width: 50px;
									@media screen and (min-width: 992px) {
									flex: 0 0 4%;
									width: 4%;
									max-width: 4%;
									}
								}
								
								&--points {
									flex: 0 0 40px;
									width: 40px;
									max-width: 40px;
									@media screen and (min-width: 992px) {
									flex: 0 0 3.5%;
									width: 3.5%;
									max-width: 3.5%;
									}
								}
								
								&--throws-2pt,
								&--throws-3pt,
								&--throws-1pt,
								&--throws-game, {
									flex: 0 0 100px;
									width: 100px;
									max-width: 100px;
									@media screen and (min-width: 992px) {
									flex: 0 0 7.5%;
									width: 7.5%;
									max-width: 7.5%;
									}
								}
								
								&--rebounds {
									flex: 0 0 100px;
									width: 100px;
									max-width: 100px;
									@media screen and (min-width: 992px) {
									flex: 0 0 6%;
									width: 6%;
									max-width: 6%;
									}
								}
								
								&--assists,
								&--blocks,
								&--steals,
								&--fouls,
								&--turnovers {
									flex: 0 0 40px;
									width: 40px;
									max-width: 40px;
									@media screen and (min-width: 992px) {
									flex: 0 0 2.5%;
									width: 2.5%;
									max-width: 2.5%;
									}
								}
								
								&--effectivity {
									flex: 0 0 40px;
									width: 40px;
									max-width: 40px;
									@media screen and (min-width: 992px) {
									flex: 0 0 3.5%;
									width: 3.5%;
									max-width: 3.5%;
									}
								}
								
								&--eval {
									flex: 0 0 40px;
									width: 40px;
									max-width: 40px;
									@media screen and (min-width: 992px) {
									flex: 0 0 3.5%;
									width: 3.5%;
									max-width: 3.5%;
									}
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
