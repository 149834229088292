#game-result-page {
	
	margin: 0 0 15px;
	@media screen and (min-width: 992px) {
	margin: 30px 0;
	}
	
	text-transform: uppercase;
	
}
