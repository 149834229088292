.game-details-admin {

	&__uploader {
		
		
		text-transform: uppercase;
		
		&__header {
			margin-bottom: 15px;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
		}
		
		&__form {
			
			&__field {
				
				padding: 10px 30px;
				background: #FFF;
				
				&:nth-child(even) {
					background: #E6E6E6;
				}
				
				//.form-label {
				//	width: 100%;
				//	text-align: center;
				//}
				
				.error {
					margin-bottom: 0;
				}
				
			}
			
			&__button {
				
				margin-top: 15px;
				
				&__content {
					justify-content: center;
				}
				
			}
		
		}
		
		
		
	}

}
