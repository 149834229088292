.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				margin-top: 20px;
				
				&__header {
					margin-bottom: 0;
					padding: 10px 15px !important;
					color: #FFFFFF;
					background: var(--primary-color);
					border-top: 2px solid #DA5E15;
					border-bottom: 2px solid #DA5E15;
					border-right: 20px solid #DA5E15;
				}
				
			}
			
		}
		
	}
	
}
