.teams-and-players {
	
	&__content {
		
		&__seasons-and-leagues {
			
			&__leagues {
			
				position: relative;
				margin-top: 15px;
				
				@media screen and (min-width: 992px) {
				margin-top: 20px;
				}
				
				&__list {
					
					&__content {
						
						justify-content: left;
						
						@media screen and (min-width: 992px) {}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
