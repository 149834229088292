.leagues-schedules {
	
	&__league-rounds-list {
		
		&__league_round {
			
			&__best-player {
				
				&__player {
					
					&__information {
						
						&__statistics {
							
							background: var(--secondary-color);
							border-top: 1px solid #FFF;
							
							.statistic-value {
								padding: 0;
								font-size: 10px;
								text-align: center;
							}
							
							&__header {
								
								padding: 2px 15px;
								
								.statistic-value {
									color: #FFF;
								}
								
							}
							
							&__data {
								
								background: var(--default-border-color);
								border-top: 1px solid #FFF;
								
								.statistic-value {
									
									padding: 5px 0;
									font-size: 12px;
									font-weight: 600;
									color: var(--primary-color);
									border-right: 2px solid #FFF;
									
									&:last-child {
										border-right: none;
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
