.leagues-switcher {
	
	@media screen and (min-width: 992px) {
	
	}
	
	&__header {
		
		margin-bottom: 10px;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 20px;
		}
		
		&__text {
			
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			font-size: 16px;
			}
			
		}
		
	}
	
	&--default {
		
		&__header {
			
			&__text {
				
				display: block;
				position: relative;
				padding: 10px 15px;
				color: #FFF;
				background: var(--secondary-color);
				
				@media screen and (min-width: 992px) {
				display: inline-block;
				padding: 10px 75px 10px 15px;
				}
				
				&::before, &::after {
					
					display: none;
					content: "";
					position: absolute;
					top: 0;
					height: 44px;
					
					@media screen and (min-width: 992px) {
					display: block;
					}
					
				}
				
				&::before {
					right: -15px;
					border-top: 44px solid var(--primary-special-color);
					border-right: 15px solid transparent;
				}
				
				&::after {
					right: 0;
					border-top: 44px solid transparent;
					border-right: 15px solid var(--primary-special-color);
				}
				
			}
			
		}
		
	}
	
	
	
}
