.game-result-details {
	
	&__diagrams {
		
		&__charts {
			
			position: relative;
			justify-content: right;
			
			&.with-extra-time {
				justify-content: center;
			}
			
			.diagrams-chart {
				
				position: relative;
				margin-bottom: 10px;
				padding: 0 0 0 15px;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 0;
				padding: 0;
				}
				
				.diagram-scales {
					
					position: absolute;
					width: 15px;
					height: 260px;
					top: -10px;
					left: -2px;
					
					@media screen and (min-width: 992px) {
					display: none;
					left: -15px;
					}
					
					.diagram-scales-value {
						
						display: block;
						height: 20px;
						margin-bottom: 4px;
						text-align: center;
						color: #989898;
						line-height: 20px;
						
						&:first-child {
							line-height: 24px;
						}
						
						&:last-child {
							line-height: 16px;
						}
						
					}
					
				}
				
				&:first-child {
					.diagram-scales {
						display: block;
					}
				}
				
				.diagram-chart-legend {
					padding-left: 15px;
					color: #989898;
				}
				
			}
			
		}
		
	}
	
}
