.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
							
							position: relative;
							margin-top: 10px;
							padding-top: 10px;
							border-top: 1px solidvar(--default-border-color);
							
							&__header {
								margin-bottom: 10px;
								font-size: 14px;
								font-weight: 600;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
