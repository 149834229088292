.service-footer-section {
	
	&__logo {
		
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin-bottom: 20px;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		justify-content: right;
		}
		
		.league-logo,
		.league-name {}
		
		.league-name {
			
			display: inline-flex;
			align-items: center;
			right: calc(50% - 70px);
			margin: 0;
			color: #FFF;
			text-align: left;
			text-transform: uppercase;
			font-size: 10px;
			line-height: 12px;
			
			@media screen and (min-width: 992px) {
			max-width: 100px;
			margin-left: 10px;
			font-size: 12px;
			line-height: 15px;
			}
			
		}
		
		.league-logo {
			
			height: 50px;
			margin-right: 10px;
			align-items: center;
			align-content: center;
			
			@media screen and (min-width: 992px) {
			height: 80px;
			}
			
		}
		
	}

}
