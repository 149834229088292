.teams-and-players {
	
	&__content {
		
		&__seasons-and-leagues {
			
			&__seasons {
				
				position: relative;
				
				&__header, &__seasons-list {}
				
				&__header {
					
					height: 45px;
					padding: 5px 15px;
					font-size: 14px;
					color: #FFF;
					text-align: right;
					line-height: 30px;
					background: var(--secondary-color);
					border-bottom: 5px solid var(--primary-special-color);
					
					@media screen and (min-width: 992px) {
					font-size: 18px;
					height: 55px;
					padding: 10px 15px;
					}
					
				}
				
				&__seasons-list {
					
					position: relative;
					
					&__seasons {
					
						position: absolute;
						top: 45px;
						max-width: none;
						width: auto;
						z-index: 1000;
						background: #FFF;
						border: 1px solid var(--default-border-color);
						
						@media screen and (min-width: 992px) {
						top: 55px;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
