.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__players-list {
			
					&__display-switcher {
						
						margin-bottom: 10px;
						
						&__content {
							justify-content: right;
						}
						
						&__switcher {
							
							display: inline-flex;
							align-content: center;
							align-items: center;
							margin-right: 15px;
							cursor: pointer;
							
							&:hover {
								color: var(--primary-special-color);
							}
							
							&__text {
								font-weight: 600;
							}
							
							&__icon {
								
								display: inline-flex;
								align-content: center;
								align-items: center;
								margin-left: 5px;
								
								svg {
									width: 20px;
									height: 16px;
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
