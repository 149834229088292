.game-result-details {
	
	&__diagrams {
		
		&__legend {
			
			margin-bottom: 10px;
			justify-content: center;
			
			@media screen and (min-width: 992px) {
			margin-bottom: 20px;
			}
			
			&__team {
				
				position: relative;
				margin: 0 15px;
				padding-left: 25px;
				line-height: 20px;
				
				.legend-team-color {
					
					position: absolute;
					top: 5px;
					left: 0;
					width: 20px;
					height: 10px;
					border: 1px solid #1A1A18;
					border-radius: 5px;
					
				}
				
				.legend-team-name {
					
					font-weight: 600;
					color: #1A1A18;
					
					&:hover {
						color: var(--primary-special-color);
					}
					
				}
				
				&.legend-home-team {
					.legend-team-color {
						background: #DA5E15;
					}
				}
				
				&.legend-away-team {
					.legend-team-color {
						background: #007731;
					}
				}
				
			}
			
		}
	
	}
	
}
