.team-details {
	
	&__team-players {
		
		&__team-leagues {
			
			position: relative;
			margin-bottom: 10px;
			padding: 0;
			
			@media screen and (min-width: 992px) {
			padding: 0 15px;
			}
			
			&__content {
				align-items: center;
				align-content: center;
			}
			
			&__label {
				
				span {
					
					padding: 5px 10px 5px 10px;
					color: #FFF;
					line-height: 20px;
					text-transform: uppercase;
					background: var(--secondary-color);
					border-right: 10px solid #DA5E15;
					
					@media screen and (min-width: 992px) {
					padding: 10px 50px 10px 15px;
					border-right-width: 15px;
					}
					
				}
				
			}
			
		}
		
	}
	
}
