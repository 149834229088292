.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				margin-top: 20px;
				
				&__header {
					
					margin-bottom: 10px;
					padding: 10px 15px !important;
					font-size: 16px;
					font-weight: 600;
					color: #FFFFFF;
					background: var(--primary-color);
					border-top: 2px solid #DA5E15;
					border-bottom: 2px solid #DA5E15;
					border-right: 20px solid #DA5E15;
					
				}
				
			}
			
		}
		
	}
	
}
