.team-page {
	
	position: relative;
	//margin-top: 10px;
	padding: 0;
	border-top: 3px solid #FFF;
	
	@media screen and (min-width: 992px) {
	margin-top: 0;
	}
	
}
