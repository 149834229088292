.free-agent-requests {
	
	&__header {
	
		margin-bottom: 15px;
		font-size: 13px;
		font-weight: 600;
		
		@media screen and (min-width: 992px) {
		font-size: 16px;
		}
		
	}
	
}
