.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
					
						&__information {
							
							padding: 10px 15px;
							border-bottom: 1px solid var(--default-border-color);
							
							&.opened {
								color: #FFFFFF;
								background: var(--secondary-color);
							}
							
							&__content {
								width: 100%;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
