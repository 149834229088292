.home-page {
	
	&__leagues {
		
		&__tables {
			
			&__tables {
				
				&__full-table-link {
				
					font-size: 12px;
					font-weight: 600;
				
					&__content {
						justify-content: right;
					}
					
				}
				
			}
			
		}
		
	}
	
}
