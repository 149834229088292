.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__leagues-statistics {
					
					&__statistics-table {
						
						&__leagues-list {
							
							&__content {
								
								overflow: hidden;
								overflow-x: scroll;
								
								@media screen and (min-width: 992px) {
								overflow-x: hidden;
								}
								
							}
							
							&__row {
								
								position: static;
								width: 1145px;
								max-width: 1145px;
								flex: 0 0 1145px;
								
								@media screen and (min-width: 992px) {
								position: relative;
								width: 100%;
								max-width: 100%;
								flex: 0 0 100%;
								padding-left: 0;
								}
								
								&__content {
									
									justify-content: left;
									align-items: center;
									align-content: center;
									padding-left: 200px;
									
									@media screen and (min-width: 992px) {
									justify-content: space-between;
									width: 100%;
									margin: 0;
									padding-left: 0;
									}
									
								}
								
								span.separator {
									
									display: inline-block;
									vertical-align: top;
									width: 1px;
									height: 14px;
									margin: 0 5px;
									background: #FFF;
									
									@media screen and (min-width: 992px) {
									height: 20px;
									}
									
								}
								
								&__value {
									
									display: flex;
									align-items: center;
									align-content: center;
									justify-content: center;
									overflow: hidden;
									text-align: center;
									padding: 0 5px;
									min-width: 24px;
									max-width: 24px;
									line-height: 14px;
									border-right: 1px solid var(--default-border-color);
									
									@media screen and (min-width: 992px) {
									flex: 0 0 2.5%;
									min-width: 2.5%;
									max-width: 2.5%;
									height: 100%;
									line-height: 18px;
									background: transparent;
									border-right-width: 2px;
									}
									
									&:last-child {
										border-right: none;
									}
									
									&__content {
										margin: 0;
										&__sub-value {
											padding: 0;
										}
									}
									
									&--season-name,
									&--team-name {
										position: absolute;
										z-index: 1;
										@media screen and (min-width: 992px) {
										position: static;
										}
									}
									
									&--season-name {
										left: 0;
										flex: 0 0 100px;
										min-width: 100px;
										max-width: 100px;
										@media screen and (min-width: 992px) {
										flex: 0 0 8%;
										min-width: 8%;
										max-width: 8%;
										}
									}
									
									&--team-name {
										left: 100px;
										flex: 0 0 100px;
										min-width: 100px;
										max-width: 100px;
										@media screen and (min-width: 992px) {
										flex: 0 0 14%;
										min-width: 14%;
										max-width: 14%;
										}
									}
									
									&--games-number,
									&--start-5 {
										flex: 0 0 35px;
										min-width: 35px;
										max-width: 35px;
										@media screen and (min-width: 992px) {
										flex: 0 0 3%;
										min-width: 3%;
										max-width: 3%;
										}
									}
									
									&--minutes-number {
										flex: 0 0 50px;
										min-width: 50px;
										max-width: 50px;
										@media screen and (min-width: 992px) {
										flex: 0 0 5%;
										min-width: 5%;
										max-width: 5%;
										}
									}
									
									&--points-number {
										flex: 0 0 40px;
										min-width: 40px;
										max-width: 40px;
										@media screen and (min-width: 992px) {
										flex: 0 0 4%;
										min-width: 4%;
										max-width: 4%;
										}
									}
									
									&--pt2-number,
									&--pt3-number,
									&--pt-game-number,
									&--ft-number {
										flex: 0 0 100px;
										min-width: 100px;
										max-width: 100px;
										@media screen and (min-width: 992px) {
										flex: 0 0 8.5%;
										min-width: 8.5%;
										max-width: 8.5%;
										}
									}
									
									&--rebounds-number {
										flex: 0 0 100px;
										min-width: 100px;
										max-width: 100px;
										@media screen and (min-width: 992px) {
										flex: 0 0 7%;
										min-width: 7%;
										max-width: 7%;
										}
									}
									
									&--assists-number,
									&--blocks-number,
									&--steals-number,
									&--turnovers-number,
									&--fouls-number {
										flex: 0 0 40px;
										min-width: 40px;
										max-width: 40px;
										@media screen and (min-width: 992px) {
										flex: 0 0 3%;
										min-width: 3%;
										max-width: 3%;
										}
									}
									
									&--effectivity-value {
										flex: 0 0 40px;
										min-width: 40px;
										max-width: 40px;
										@media screen and (min-width: 992px) {
										flex: 0 0 4%;
										min-width: 4%;
										max-width: 4%;
										}
									}
									
									&--eval-value {
										flex: 0 0 40px;
										min-width: 40px;
										max-width: 40px;
										@media screen and (min-width: 992px) {
										flex: 0 0 3%;
										min-width: 3%;
										max-width: 3%;
										}
									}
									
								}
							
							}
							
							&__league {
								
								padding: 0;
								background: var(--white);
								
								@media screen and (min-width: 992px) {
								margin-top: 0;
								font-weight: 600;
								border: none;
								}
								
								&:nth-child(odd) {
									@media screen and (min-width: 992px) {
									background: #E6E6E6;
									}
								}
								
								&__content {
									margin-left: 0;
									margin-right: 0;
								}
								
								&__value {
									
									overflow: hidden;
									display: flex;
									flex-direction: column;
									min-height: 40px;
									font-size: 10px;
									font-weight: 600;
									background: #FFF;
									border-right: 1px solid var(--default-border-color);
									border-bottom: 1px solid var(--default-border-color);
									
									@media screen and (min-width: 992px) {
									background: transparent;
									}
									
									@media screen and (min-width: 992px) {
									min-height: 46px;
									height: 100%;
									padding: 5px 5px;
									font-size: 12px;
									border-bottom: none;
									}
									
									&:last-child {
									border-right: none;
									}
									
									span.separator {
										background: var(--primary-color);
									}
									
									&--season-name {
										
										//display: flex;
										//align-content: center;
										//align-items: center;
										//justify-content: center;
										//padding: 0 5px;
										
										span {
											//display: flex;
											//overflow: hidden;
											//height: 30px;
											//line-height: 15px;
										}
										
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
