.game-tables-team-table {
	
	.game-tables-team-table-content {
		overflow: hidden;
		overflow-x: scroll;
		@media screen and (min-width: 992px) {
		overflow-x: hidden;
		}
	}
	
	.game-tables-team-table-row {
		
		position: static;
		padding: 0 0 0 172px;
		width: 800px;
		max-width: 800px;
		flex: 0 0 800px;
		@media screen and (min-width: 992px) {
		position: relative;
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		padding-left: 0;
		}
		
		.game-tables-team-table-row-content {
			
			width: 100%;
			height: 100%;
			align-items: center;
			align-content: center;
			margin: 0;
			justify-content: space-between;
			
			@media screen and (min-width: 992px) {}
			
		}
		
		.table-row-cell {
			
			min-width: 24px;
			max-width: 24px;
			height: 25px;
			padding: 0;
			text-align: center;
			border-right: 2px solid #E1E4E4;
			
			@media screen and (min-width: 992px) {
			min-width: 3%;
			max-width: 3%;
			height: 100%;
			background: transparent;
			}
			
			&:last-child {
				border-right: none;
			}
			
			&.team-player-number, &.team-summary-number, &.team-number,
			&.team-player-name, &.team-name, &.team-summary-name {
				position: absolute;
				z-index: 1;
				@media screen and (min-width: 992px) {
				position: static;
				}
			}
			
			&.team-player-number, &.team-summary-number, &.team-number {
				left: 0;
				@media screen and (min-width: 992px) {
				left: auto;
				}
			}
			
			&.team-player-name, &.team-name, &.team-summary-name {
				left: 36px;
				@media screen and (min-width: 992px) {
				left: auto;
				}
			}
			
			&.team-player-number, &.team-summary-number, &.team-number {
				min-width: 36px;
				max-width: 36px;
				@media screen and (min-width: 992px) {
				min-width: 4.5%;
				max-width: 4.5%;
				}
			}
			
			&.team-player-name, &.team-name, &.team-summary-name {
				min-width: 136px;
				max-width: 136px;
				@media screen and (min-width: 992px) {
				min-width: 17%;
				max-width: 17%;
				}
			}
			
			&.team-player-points, &.team-summary-points, &.team-points {
				min-width: 32px;
				max-width: 32px;
				@media screen and (min-width: 992px) {
				min-width: 4%;
				max-width: 4%;
				}
			}
			
			&.team-player-minutes, &.team-summary-minutes, &.team-minutes  {
				min-width: 40px;
				max-width: 40px;
				@media screen and (min-width: 992px) {
				min-width: 5%;
				max-width: 5%;
				}
			}
			
			&.team-player-throws, &.team-summary-throws, &.team-throws {
				min-width: 36px;
				max-width: 36px;
				@media screen and (min-width: 992px) {
				min-width: 4.5%;
				max-width: 4.5%;
				}
			}
			
			&.team-player-effectivity, &.team-summary-effectivity, &.team-effectivity {
				min-width: 28px;
				max-width: 28px;
				@media screen and (min-width: 992px) {
				min-width: 4.5%;
				max-width: 4.5%;
				}
			}
			
			&.team-player-eval, &.team-summary-eval, &.team-eval {
				min-width: 32px;
				max-width: 32px;
				@media screen and (min-width: 992px){
				min-width: 4%;
				max-width: 4%;
				}
			}
			
		}
		
	}
	
}
