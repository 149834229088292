.team-details {
	
	&__team-players {
		
		&__list {
			
			position: relative;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			margin-top: 10px;
			}
		
			&__team-name {}
			
			&__players {}
			
		}
		
	}
	
}
