.teams-and-players {
	
	&__content {
		
		&__seasons-and-leagues {
			
			&__seasons {
				
				&__seasons-list {
					
					&__seasons {
						
						&__season {
							
							padding: 5px 15px;
							white-space: nowrap;
							overflow: hidden;
							font-size: 14px;
							text-overflow: ellipsis;
							border-bottom: 1px solid var(--default-border-color);
							cursor: pointer;
							
							@media screen and (min-width: 992px) {
							font-size: 16px;
							}
							
							&:hover {
								color: var(--primary-special-color);
							}
							
							&:last-child {
								border-bottom: none;
							}
							
						}
					
					}
					
				}
				
			}
			
		}
		
	}
	
}
