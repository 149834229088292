.leagues-switcher {
	
	
	&__leagues-list {
		
		&__league {
			
			padding: 0;
			text-transform: uppercase;
			background: var(--default-border-color);
			
			span {
				
				display: block;
				padding: 5px 15px;
				cursor: pointer;
				
				&:hover {
					color: var(--primary-special-color);
				}
				
				@media screen and (min-width: 992px) {}
				
			}
			
			&.selected {
				
				font-weight: 600;
				background: var(--secondary-color);
				background: var(--primary-special-color);
				
				span {
					color: #FFF;
				}
				
			}
			
		}
		
	}
	
	
	&--default {
		
		&__leagues-list {
			
			&__league {
			
				margin-left: 15px;
				
				&:first-child {
					margin-left: 0;
				}
				
			}
			
		}
		
	}
	
}
