.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__team-files {
				
				margin-top: 20px;
				
				&__header {
					padding: 0;
					font-size: 16px;
					font-weight: 600;
				}
				
			}
			
		}
		
	}
	
}
