.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__statistics-switcher {
				
				margin: 0 0 25px;
				padding: 0 15px;
				
				@media screen and (min-width: 992px) {
				margin: 0 0 35px;
				padding: 0 15px;
				border-top: none;
				border-bottom: none;
				}
				
				&__content {
					justify-content: space-between;
					border-bottom: 5px solid var(--primary-color);
					@media screen and (min-width: 992px) {
					justify-content: center;
					}
				}
				
				&__button {
					position: relative;
					height: 40px;
					padding: 10px 10px;
					font-size: 12px;
					color: var(--white);
					text-align: center;
					text-transform: uppercase;
					line-height: 20px;
					cursor: pointer;
					background: var(--primary-color);
					border: 2px solid var(--white);
					
					@media screen and (min-width: 992px) {
					height: 50px;
					margin-right: 100px;
					padding: 10px 50px;
					font-size: 28px;
					line-height: 30px;
					background: none;
					border: none;
					}
					
					&:last-child {
						margin-right: 0;
					}
					
					&:hover {
						color: var(--primary-special-color);
					}
					
					span {
						display: block;
						line-height: 18px;
						@media screen and (min-width: 992px) {
						line-height: 30px;
						}
					}
					
					&.selected {
						
						font-weight: 600;
						color: var(--white);
						background: var(--primary-special-color);
						
						@media screen and (min-width: 992px) {
						color: var(--primary-color);
						background: var(--white);
						border-bottom: 3px solid var(--primary-special-color);
						box-shadow: 10px 10px 5px var(--primary-color);
						}
						
					}
					
				}
			
			}
			
		}
		
	}
	
}
