.game-details-admin {
	
	&__header {
	
		&__league {
			margin: 20px 0;
			font-size: 16px;
			font-weight: 600;
			text-align: center;
		}
		
	}

}
