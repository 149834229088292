.team-details {
	
	&__team-crew {
		
		margin-bottom: 0;
		position: relative;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 20px;
		padding: 10px 0 0;
		}
		
		.default-page-header {
			
			padding: 10px 0;
			margin-top: 10px;
			border-top: 2px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding: 25px;
			border-top: none;
			}
			
			.default-page-header-heading {
				font-size: 16px;
				@media screen and (min-width: 992px) {
				font-size: 20px;
				}
			}
			
		}
	
		&__team-crew-person {
			
			margin-bottom: 10px;
			font-size: 13px;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			margin-bottom: 20px;
			font-size: 16px;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
			
			.team-crew-person-label {
				margin-right: 10px;
			}
			
			.team-crew-person-name {
				font-weight: 600;
			}
			
			
		}
		
	}
	
}
