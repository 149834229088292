.game-result-details {
	
	&__progress {
		
		position: relative;
		padding: 0 20px;
		
		@media screen and (min-width: 992px) {
		padding: 0 15px;
		}
		
		.no-events-message {
			margin-top: 10px;
			padding: 15px;
			color: var(--primary-special-color);
			background: #FFF;
			border: 1px solid var(--default-border-color);
			//border-top: none;
		}
		
		&__events {
			
			position: relative;
			
			&__event {
				
				font-size: 9px;
				font-weight: 600;
				text-align: center;
				line-height: 15px;
				background: #FFF;
				border-bottom: 1px solid #FFF;
				
				@media screen and (min-width: 992px) {
				min-height: 60px;
				font-size: 12px;
				line-height: 20px;
				//border-bottom: none;
				}
				
				&:first-child {
					border-top: 1px solid #FFF;
					@media screen and (min-width: 992px) {
					//border-top: none;
					}
				}
				
				&:nth-child(odd) {
					
					background: #EBEBEB;
					
					.table-event-information {
						background: var(--default-border-color);
					}
					
				}
				
				&--game-event {
					
					padding: 15px 15px;
					font-size: 14px;
					border-top: 2px solid var(--default-border-color)!important;
					border-bottom: 2px solid var(--default-border-color);
					
					@media screen and (min-width: 992px) {
					border-top: none!important;
					border-bottom: none;
					}
			
				}
				
				&--quarter-event {
					
					padding: 10px 15px;
					font-size: 12px;
					border-top: 1px solid var(--default-border-color)!important;
					border-bottom: 1px solid var(--default-border-color);
					
				}
				
				&__content {
					height: 100%;
					align-items: center;
					justify-content: center;
				}
				
				&__data,
				&__information {
					
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					height: 100%;
					padding: 5px;
					
					@media screen and (min-width: 992px) {
					padding: 0 15px;
					}
					
				}
				
				&__data {
					
					flex-direction: row;
					
					&.event-owner {
						
						&.home-team-event {
							border-right: 5px solid #017A3B;
							@media screen and (min-width: 992px) {
							border-right-width: 10px;
							}
						}
						
						&.away-team-event {
							border-left: 5px solid #017A3B;
							@media screen and (min-width: 992px) {
							border-left-width: 10px;
							}
						}
					}
					
					.player-name,
					.player-number {
						display: inline-flex;
					}
					
					.player-name {
						
						margin-right: 5px;
						
						@media screen and (min-width: 992px) {}
						
						&:last-child {
							margin-right: 0;
						}
						
					}
					
					.player-number {}
					
				}
				
				&__information {
					
					flex-direction: row;
					flex-wrap: wrap;
					background: #EBEBEB;
					
					.event-icon,
					.event-type,
					.event-quarter {}
					
					.event-icon,
					.event-type {
						display: inline-flex;
					}
					
					.event-icon {
						
						margin-right: 5px;
						
						img {
							max-width: 20px;
							max-height: 15px;
							@media screen and (min-width: 992px) {
							max-width: 40px;
							max-height: 24px;
							}
						}
						
					}
					
					.event-type {
						
						font-size: 10px;
						color: #1A1A18;
						
						@media screen and (min-width: 992px) {
						font-size: 16px;
						}
						
					}
					
					.event-quarter {
						display: block;
						flex: 0 0 100%;
						width: 100%;
						margin-top: 5px;
						@media screen and (min-width: 992px) {
							margin-top: 0;
						}
					}
					
				}
				
			}
			
		}
		
	}

}
