* {
	box-sizing: border-box;
	transition: 0.3s ease all;
}


html, body {
	position: relative;
	min-height: 100%;
}


body {
	
	width: 100%;
	max-width: 100%;
	min-width: 320px;
	min-height: 100vh;
	overflow: hidden;
	overflow-y: scroll;
	font-family: 'silkaregular', "Calibri", "Tahome";
	font-size: 12px;
	color: var(--default-font-color);
	line-height: 20px;
	background: #F8F8F8;
	
	@media screen and (min-width: 992px) {
	padding-top: 0;
	padding-bottom: 381px;
	font-size: 11px;
	line-height: 20px;
	}
	
	@media screen and (min-width: 1366px) {
	font-size: 13px;
	line-height: 24px;
	}
	
}



a {
	text-decoration: none;
	color: var(--default-font-color);
	&:hover {
		text-decoration: none;
		color: var(--default-hover-color);
	}
}


.error {
	color: var(--default-error-color);
	border-color: var(--default-error-color);
}


@import "@Assets/@Styles/SCSS/@Globals/Alerts/index.scss";
@import "@Assets/@Styles/SCSS/@Globals/Loaders/index.scss";
@import "@Assets/@Styles/SCSS/@Globals/Bars/index.scss";
@import "@Assets/@Styles/SCSS/@Globals/Messages/index.scss";
@import "@Assets/@Styles/SCSS/@Globals/Popups/index.scss";
