.article-detail {
	
	&__content {
		
		&__header {
			
			&__image-and-title {
				
				&__image {
					
					margin-bottom: 15px;
					@media screen and (min-width: 992px) {
					margin-bottom: 20px;
					}
					
					&__picture {
						margin: 0;
						justify-content: center;
						align-content: center;
						align-items: center;
					}
					
					img {
						max-height: 150px;
						@media screen and (min-width: 992px) {
						max-height: 300px;
						}
					}
					
				}
				
			}
			
		}
		
	}
	
}
