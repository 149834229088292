.team-details {
	
	&__team-information {
		
		&__team-next-games {
		
			margin-top: 10px;
			padding-top: 10px;
			border-top: 2px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
			}
			
		}
		
	}
	
}
