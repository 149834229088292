.team-details {
	
	&__team-information {
		
		&__team-photo {
			
			&.empty {
				display: none;
				@media screen and (min-width: 992px) {
				display: block;
				}
			}
			
			padding: 10px;
			
			@media screen and (min-width: 992px) {
			padding: 0 45px 0 15px;
			}
			
			&__picture {
				
				height: 100%;
				//margin: 0 -30px;
				justify-content: center;
				align-content: start;
				align-items: start;
				
				&__image {
					max-height: 200px;
					@media screen and (min-width: 992px) {
					max-height: 300px;
					}
				}
				
			}
		
		}
		
	}
	
}
