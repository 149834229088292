

.leagues-tables-leagues-list-component {
	
	margin-bottom: 5px;
	padding: 0 0 0 20px;
	
	@media screen and (min-width: 992px) {
	margin-bottom: 0;
	padding: 0;
	}
	
	.leagues-list-league {
		
		margin-bottom: 5px;
		margin-right: 0;
		padding: 8px 10px;
		text-align: center;
		background: var(--primary-color);
		
		@media screen and (min-width: 992px) {
		padding: 10px;
		border-left: 10px solid var(--primary-special-color);
		}
		
		&:last-child {}
		
		.leagues-list-name {
			
			padding: 0;
			font-size: 11px;
			color: #FFF;
			line-height: 18px;
			cursor: pointer;
			
			@media screen and (min-width: 992px) {
			font-size: 15px;
			line-height: 24px;
			}
			
			span {
				
				overflow-y: scroll;
				display: block;
				width: 100%;
				height: 18px;
				
				@media screen and (min-width: 992px) {
				overflow: hidden;
				height: 24px;
				}
				
			}
			
			&:hover, &.selected {
				color: var(--primary-special-color);
			}
			
			&.selected {
				font-weight: 600;
			}
			
		}
		
	}
}


.home-page {
	
	&__leagues {
		
		&__tables {
		
		}
		
	}
	
}
