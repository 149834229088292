.player-profile {
	
	&__captain-zone {
	
		&__team {
			
			&__team-files {
				
				&__files-list {
					
					&__add-file {
						
						margin: 10px 0;
						
						&__button {
							
							&__content {
								justify-content: right;
							}
							
							.text-button {
								margin-right: 15px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
	
	}
	
}
