.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__leagues-statistics {
					
					&__statistics-table {
						
						&__header {
							
							position: relative;
							width: 100%;
							font-size: 20px;
							color: var(--white);
							line-height: 40px;
							background: var(--primary-color);
							border-bottom: 2px solid var(--primary-special-color);
							
							@media screen and (min-width: 992px) {
							width: auto;
							min-width: 30%;
							font-size: 22px;
							text-align: center;
							line-height: 50px;
							border-bottom-width: 5px;
							}
							
							@media screen and (min-width: 992px) {
							
							&::before, &::after {
								position: absolute;
								top: 0;
								content: "";
								width: 5px;
								height: 45px;
							}
							
							&::before {
								right: 0;
								border-top: 55px solid transparent;
								border-right: 5px solid var(--primary-special-color);
							}
							
							&::after {
								right: -5px;
								border-top: 55px solid var(--primary-special-color); ;
								border-right: 5px solid transparent;
							}
							
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
