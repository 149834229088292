.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
							
							&__add-player {
								
								&__form {
									
									margin-top: 10px;
									
									&__legend {
										
										margin-bottom: 10px;
										
										span {
											display: block;
											padding: 5px;
											text-align: center;
											border: 1px solid var(--default-border-color);
										}
										
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
