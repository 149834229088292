.teams-and-players {
	
	&__content {
		
		&__seasons-and-leagues {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						margin-bottom: 5px;
						padding: 5px 15px;
						background: var(--light-grey-color);
						cursor: pointer;
						
						@media screen and (min-width: 992px) {
						margin-right: 15px;
						margin-bottom: 10px;
						}
						
						&:hover, &.selected {
							color: #FFF;
						}
						
						&:hover {
							background: var(--secondary-color);
						}
						
						&.selected {
							background: var(--primary-special-color);
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
