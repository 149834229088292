.team-details {
	
	&__team-information {
		
		&__team-next-games {
			
			&__games-list {
				
				&__header {
					
					padding: 5px 15px;
					text-transform: uppercase;
					background: #E6E6E6;
					border-bottom: 2px solid var(--primary-color);
					
					&__game-order-nr {
						flex: 0 0 7%;
						max-width: 7%;
					}
					
					&__game-date-day,
					&__game-date-hour {
						flex: 0 0 12%;
						max-width: 12%;
					}
					
					&__game-arena {
						flex: 0 0 36%;
						max-width: 36%;
					}
					
					&__game-opponent {
						flex: 0 0 33%;
						max-width: 33%;
					}
					
					&__game-order-nr,
					&__game-date-day,
					&__game-date-hour,
					&__game-arena,
					&__game-opponent {
						padding: 0;
						font-size: 12px;
						text-align: center;
					}
					
					&__game-order-nr,
					&__game-date-day,
					&__game-date-hour,
					&__game-arena {}
					
				}
				
			}
			
		}
		
	}
	
}
