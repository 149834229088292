.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
					
						&__team-information {
							
							margin-top: 5px;
							
							&__team-name, &__team-short-name  {
								
								.form-label {
									font-size: 12px;
									color: #2A2A2A;
								}
								
								.form-field {
									margin-bottom: 0;
								}
								
								
							}
							
							&__team-active-status, &__team-actions  {
								
								display: flex;
								align-items: center;
								align-content: center;
								
							}
							
							&__team-active-status {
								
								font-weight: 600;
								
								&__label {
									margin-right: 5px;
								}
								
							}
							
							&__team-actions {
								display: none;
							}
							
						}
						
					}
					
				}
				
			}
			
		}

	}
	
}
