.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__played-games {
					
					&__header {
						
						&__leagues {
							
							&__selected-league {
								
								position: relative;
								display: flex;
								align-content: center;
								align-items: center;
								
								span {
									display: block;
									width: 100%;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}
								
								svg {
									margin-left: 10px;
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
