.team-leagues-switcher {
	
	position: relative;
	text-transform: uppercase;
	
	&__league {
		
		height: 30px;
		font-weight: 600;
		line-height: 30px;
		
	}
	
	&__button {
		
		padding: 0;
		text-align: center;
		
		span {
			
			display: inline-flex;
			width: 100%;
			height: 30px;
			align-content: center;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			
			svg {
				width: 20px;
				height: 15px;
			}
			
		}
	}
	
	&__leagues-list {
		
		position: absolute;
		top: 30px;
		background: #FFF;
		z-index: 1000;
		border: 1px solid var(--default-border-color);
		
		&__league {
			
			height: 30px;
			line-height: 30px;
			border-bottom: 1px solid var(--default-border-color);
			cursor: pointer;
			
			&:hover {
				color: #DA5E15;
			}
			
			&:last-child {
				border-bottom: none;
			}
			
		}
		
	}

}
