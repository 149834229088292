.leagues-schedules {
	
	&__leagues-and-rounds-switcher {
	
		margin-bottom: 15px;
		padding: 0;
		border-top: 3px solid var(--primary-special-color);
		border-bottom: 3px solid var(--primary-special-color);
		
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		border-top: none;
		border-bottom: 2px solid var(--default-border-color);
		}
	
	}
	
}
