.leagues-tables-page {
	
	position: relative;
	padding: 0;
	
	.leagues-and-seasons {
		
		text-transform: uppercase;
		padding-bottom: 10px;
		
		@media screen and (min-width: 992px) {
		padding-bottom: 0;
		}
		
		.leagues-and-seasons-leagues {
			
			padding: 0 25px;
			
			@media screen and (min-width: 992px) {
			padding: 0 15px;
			}
			
			.leagues-list-league {
				
				margin-bottom: 10px;
				margin-right: 10px;
				padding: 5px 10px;
				line-height: 20px;
				cursor: pointer;
				
				@media screen and (min-width: 992px) {
				margin-right: 15px;
				padding: 5px 15px;
				color: #FFF;
				line-height: 30px;
				background: var(--primary-color);
				}
				
				&:hover {
					color: var(--primary-special-color);
				}
				
				&.selected {
					color: #FFF;
					background: var(--primary-special-color);
				}
				
			}
			
		}
		
		.leagues-and-seasons-seasons {
			
			padding-right: 25px;
			color: #222;
			
			@media screen and (min-width: 992px) {
			padding-right: 0;
			}
			
			.leagues-and-seasons-seasons-content {
				@media screen and (min-width: 992px) {
				justify-content: right;
				}
			}
			
			.seasons-label, .seasons-list {
				padding: 3px 10px;
				background: #E6E6E6;
				@media screen and (min-width: 992px) {
				padding: 5px 15px;
				}
			}
			
			.seasons-label {
				text-align: center;
				line-height: 20px;
				border-bottom: 1px solid var(--default-border-color);
				@media screen and (min-width: 992px) {
				text-align: left;
				line-height: 30px;
				border-right: 1px solid #CCC;
				}
			}
			
			.seasons-list {
				
				.form-control {
					
					font-size: 14px;
					height: 20px;
					padding: 0;
					border: none;
					background: transparent;
					
					@media screen and (min-width: 992px) {
					height: 30px;
					padding: 5px 10px;
					}
					
					&:disabled {
						text-align: center;
						appearance: none;
						line-height: 20px;
					}
					
				}
				
			}
			
		}
		
	}
	
	.league-table {
		
		position: relative;
		padding-top: 0;
		border-top: 2px solid var(--primary-color);
		
		@media screen and (min-width: 992px) {
		padding-top: 20px;
		}
		
		.league-table-table {
			
			position: relative;
			margin-top: 10px;
			margin-bottom: 20px;
			padding: 0 20px;
			//padding-bottom: 10px;
			text-transform: uppercase;
			border-bottom: 3px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			margin-top: 0;
			margin-bottom: 0;
			padding: 0 15px;
			border-bottom: none;
			}
			
			.leagues-tables-group {
				
				margin-bottom: 20px;
				padding: 0;
				
				@media screen and (min-width: 992px) {
				margin-bottom: 40px;
				}
				
				&:last-child {
					@media screen and (min-width: 992px) {
					margin-bottom: 0;
					}
				}
				
				.leagues-tables-group-header {
					margin-bottom: 10px;
					font-size: 16px;
					font-weight: 600;
				}
				
				
			}
			
			.league-table-row {
				
				background: #E6E6E6;
				
				&:nth-child(odd) {
					background: #FFF;
				}
				
				&.league-table-head {
					background: #E6E6E6;
				}
				
				.league-table-cell {
					
					height: 35px;
					padding: 5px 0;
					font-size: 10px;
					color: #222;
					text-align: center;
					line-height: 25px;
					
					@media screen and (min-width: 992px) {
					height: 50px;
					padding: 10px 0;
					font-size: 12px;
					line-height: 30px;
					}
					
					&.team-position {
						width: 6%;
						max-width: 6%;
						flex: 0 0 6%;
						@media screen and (min-width: 992px) {}
					}
					
					&.team-logo {
						width: 10%;
						max-width: 10%;
						flex: 0 0 10%;
						padding-right: 0;
					}
					
					&.team-name {
						width: 28%;
						max-width: 28%;
						flex: 0 0 28%;
					}
					
					&.team-points {
						width: 10%;
						max-width: 10%;
						flex: 0 0 10%;
						padding-left: 30px;
					}
					
					&.team-games {
						width: 8%;
						max-width: 8%;
						flex: 0 0 8%;
					}
					
					&.team-balance {
						width: 10%;
						max-width: 10%;
						flex: 0 0 10%;
					}
					
					&.team-home-games, &.team-away-games {
						width: 8%;
						max-width: 8%;
						flex: 0 0 8%;
					}
					
					&.team-small-points {
						width: 20%;
						max-width: 20%;
						flex: 0 0 20%;
					}
					
					&.team-small-points-diff {
						width: 10%;
						max-width: 10%;
						flex: 0 0 10%;
					}
					
					
					a {
						color: #222;
						&:hover {
							color: var(--primary-special-color);
						}
					}
					
				}
				
			}
			
			.league-table-head {
				
				.league-table-cell {
					
					&.team-position, &.team-logo, &.team-name {
						color: #FFF;
						background: var(--primary-color);
					}
					
					&.team-position {
						border-right: 1px solid #FFF;
					}
					
					&.team-name {
						
						position: relative;
						text-align: left;
						
						&::before, &::after {
							content: "";
							position: absolute;
							top: 0;
							width: 15px;
						}
						
						&::before {
							right: 0;
							border-top: 50px solid transparent;
							border-right: 15px solid var(--primary-color);
						}
						
						&::after {
							right: -15px;
							border-top: 50px solid var(--primary-color);
							border-right: 15px solid transparent;
						}
						
					}
					
					span.label {
						display: none;
						@media screen and (min-width: 992px) {
						display: block;
						}
					}
					
					span.short-label {
						@media screen and (min-width: 992px) {
						display: none;
						}
					}
					
				}
				
			}
			
			.league-table-team {
				
				.league-table-cell {
					
					&.team-logo {
						
						display: flex;
						padding: 5px 5px 5px 0;
						justify-content: center;
						align-items: center;
						
						@media screen and (min-width: 992px) {
						padding: 5px 15px 5px 0;
						}
						
						a {
							
							display: block;
							
							img {
								max-height: 25px;
								@media screen and (min-width: 992px) {
								max-height: 40px;
								}
							}
							
							&:hover {
								opacity: 0.7;
							}
							
						}
						
					}
					
					&.team-name {
						text-align: left;
						padding-left: 5px;
					}
					
				}
				
			}
			
			&.amateur-league {
				
				.league-table-row {
					
					.league-table-cell {
						&.team-name {
							width: 26%;
							max-width: 26%;
							flex: 0 0 26%;
						}
					}
					
					&.league-table-team {
						
						.league-table-cell {
							
							&.team-name {
								line-height: 15px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
	.leagues-tables-statistics {
		
		padding: 0 20px;
		
		@media screen and (min-width: 992px) {
		padding: 0 0 0 60px;
		}
		
		.leagues-tables-statistics-content {
		align-items: flex-start;
		align-content: flex-start;
		}
		
		.league-statistics-types {
			
			margin-bottom: 10px;
			padding: 0 10px 10px;
			font-size: 14px;
			text-align: right;
			text-transform: uppercase;
			border-bottom: 1px solid var(--default-border-color);
			
			@media screen and (min-width: 992px) {
			margin-bottom: 15px;
			padding: 0;
			font-size: 16px;
			}
			
			.types-label {
				float: left;
				font-weight: 600;
			}
			
			.types-type {
				cursor: pointer;
				&:hover {
					color: var(--primary-special-color);
				}
				&.selected {
					font-weight: 600;
				}
			}
			
			.types-separator {
				margin: 0 3px;
			}
			
			
		}
		
		.league-statistics-header {
			
			@media screen and (min-width: 992px) {
			background: #E6E6E6;
			border-bottom: 2px solid var(--primary-color);
			}
			
			
			.statistics-type {
				
				width: 100%;
				padding: 5px 15px;
				font-size: 10px;
				color: #FFF;
				text-transform: uppercase;
				border-bottom: 1px solid var(--default-border-color);
				line-height: 20px;
				background: #1D4289;
				cursor: pointer;
				
				@media screen and (min-width: 992px) {
				width: auto;
				color: #222222;
				padding: 10px 10px;
				font-size: 12px;
				line-height: 30px;
				border-bottom: none;
				background: none;
				}
				
				&:last-child {
					border-bottom: none;
				}
				
				&:hover {
					color: var(--primary-special-color);
				}
				
				&.selected {
					color: #FFF;
					background: var(--primary-special-color);
					@media screen and (min-width: 992px) {
					background: var(--primary-color);
					}
				}
			}
			
		}
		
		.league-statistics-table-content {
			
			padding-left: 20px;
			font-size: 10px;
			text-transform: uppercase;
			
			@media screen and (min-width: 992px) {
			padding-left: 15px;
			font-size: 12px;
			}
			
			.league-statistics-table-player {
				
				padding: 5px 10px;
				background: #FFF;
				
				@media screen and (min-width: 992px) {
				padding: 5px 15px;
				}
				
				&:nth-child(even) {
					background: #E6E6E6;
				}
				
				.player-place {
					text-align: center;
					font-weight: 600;
				}
				
				.player-image {
					
					padding: 0;
					text-align: center;
					
					a {
						
						display: block;
						
						&:hover {
							opacity: 0.7;
						}
						
						img {
							max-height: 35px;
							@media screen and (min-width: 992px) {
							max-height: 50px;
							}
						}
						
					}
					
				}
				
				.player-data {
					
					padding: 0 5px;
					
					@media screen and (min-width: 992px) {
					padding: 0 15px;
					}
					
					.player-name-data, .player-team-data {
						margin: 0;
					}
					
					.player-name, .player-team {
						padding: 0;
						line-height: 15px;
					}
					
					.player-name {
						font-weight: 600;
						@media screen and (min-width: 992px) {
						margin-bottom: 5px;
						line-height: 20px;
						}
					}
					
					.player-team {
						padding-top: 5px;
						font-size: 9px;
						line-height: 15px;
						@media screen and (min-width: 992px) {
						font-size: 11px;
						border-top: 1px solid #CCC;
						}
					}
					
				}
				
				.player-score {
					display: flex;
					padding: 0;
					justify-content: center;
					font-size: 14px;
					font-weight: 600;
					text-align: center;
					@media screen and (min-width: 992px) {
					align-items: center;
					padding: 0 15px;
					font-size: 18px;
					}
				}
				
			}
			
		}
		
		.league-statistics-number {
			margin-top: 20px;
			text-align: right;
			text-transform: uppercase;
			font-weight: 600;
			span {
				cursor: pointer;
				&:hover {
					color: var(--primary-special-color);
				}
			}
		}
		
	}
	
}
