.leagues-schedules {
	
	&__leagues-and-rounds-switcher {
		
		&__rounds-switcher {
		
			&__round {
				
				&--selected-round {
					
					overflow: hidden;
					height: 40px;
					padding-right: 40px;
					color: #FFF;
					background: var(--secondary-color);
					
					@media screen and (min-width: 992px) {
					height: 50px;
					background: var(--primary-color);
					}
					
					&__content {
						height: 100%;
						padding: 0 15px;
						align-content: center;
						align-items: center;
					}
					
					&__label, &__icon {
						display: inline-flex;
					}
					
					&__label {
						
						width: 100%;
						font-size: 12px;
						font-weight: 600;
						text-transform: uppercase;
						line-height: 15px;
						
						@media screen and (min-width: 992px) {
						font-size: 14px;
						line-height: 20px;
						}
						
					}
					
					&__icon {
						
						position: absolute;
						top: 12px;
						right: 15px;
						cursor: pointer;
						
						@media screen and (min-width: 992px) {
						top: 17px;
						}
						
						svg {
							width: 16px;
							height: 16px;
						}
						
					}
					
					
				}
				
			}
			
		}
		
	}
	
}
