.team-header-crew {
	
	margin-top: 10px;
	padding: 5px 15px;
	font-size: 12px;
	color: var(--secondary-color);
	text-align: center;
	line-height: 20px;
	
	@media screen and (min-width: 992px) {
	margin-top: 0;
	padding: 15px 0;
	font-size: 16px;
	text-align: right;
	}
	
	span {
		margin-right: 15px;
		color: var(--black-grey-color);
		&:last-child {
			margin-right: 0;
		}
		strong {
			color: var(--primary-color);
		}
	}
	
}
