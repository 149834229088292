.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__leagues {
				
				&__list {
					
					&__league {
						
						&__players {
							
							&__add-player {
							
								&__form {
									
									margin-top: 10px;
									
									&__field, &__submit {}
									
									&__submit {
										
										display: flex;
										align-content: end;
										align-items: end;
										
										.form-field-content {
											justify-content: center;
										}
										
									}
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
