.free-agent-requests {
	
	
	&__create-request {
		
		&__form {
			
			margin-top: 10px;
			padding: 10px 15px 0;
			background: #FFF;
			border: 1px solid var(--default-border-color);
			
			&__header {
				
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 600;
				color: #1C7430;
				
				@media screen and (min-width: 992px) {
				font-size: 16px;
				}
				
			}
			
			&__field {
			
				display: flex;
				align-content: flex-end;
				align-items: flex-end;
				
			}
			
		}
		
	}
	
}
