.service-copyrights-section {
	
	//@media screen and (min-width: 992px) {
	//border-top: 5px solid var(--primary-color);
	//margin-top: 20px;
	//}
	
	@media screen and (min-width: 992px) {
	position: absolute;
	bottom: 0;
	}
	
	&__links,
	&__information,
	&__development {
		height: 20px;
		text-transform: uppercase;
		line-height: 20px;
	}
	
	&__links {
		
		margin-bottom: 10px;
		vertical-align: top;
		text-align: center;
		
		@media screen and (min-width: 992px) {
		margin-bottom: 0;
		text-align: left;
		}
		
		a {
			display: inline-block;
			margin: 0 10px;
		}
		
	}
	
	&__information {
		
		&__label,
		&__value {}
		
		&__label {}
		
		&__value {
			margin-left: 5px;
			font-weight: 600;
		}
		
	}
	
	&__development {
		
		margin-top: 10px;
		text-align: center;
		
		@media screen and (min-width: 992px) {
		margin-top: 0;
		text-align: right;
		}
		
		&__label, a {}
		
		&__label {}
		
		a {
			margin-left: 5px;
			font-weight: 600;
		}
		
	}
	
}
