.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
			
				&__leagues-statistics {
				
					position: relative;
				
				}
			
			}
			
		}
		
	}
	
}
