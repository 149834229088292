.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__best-scores {
					
					&__list {
						
						&__header {
							
							height: 40px;
							@media screen and (min-width: 992px) {
							height: 50px;
							}
							
							&__value {
								
								padding: 0 5px;
								font-size: 10px;
								color: var(--white);
								text-align: center;
								line-height: 40px;
								background: var(--primary-color);
								
								@media screen and (min-width: 992px) {
								padding: 0 15px;
								font-size: 14px;
								font-weight: 600;
								line-height: 50px;
								}
								
							}
							
							
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
