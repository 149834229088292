.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__players {
				
				&__player-add {
				
					&__player-form {
						
						margin-top: 10px;
						padding: 10px 15px;
						background: #FFF;
						border: 1px solid var(--default-border-color);
						
						&__field {
							
							.form-label.error {
								margin: 10px 0 0;
								font-size: 10px;
								line-height: 12px;
							}
							
						}
						
						&__submit {
							
							display: flex;
							justify-content: center;
							
							button.btn-primary  {
								height: 30px;
								padding: 0 15px;
								font-size: 12px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
