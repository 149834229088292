.player-details {
	
	&__details-data {
		
		&__statistics-data {
			
			&__content {
				
				&__leagues-statistics {
					
					&__statistics-table {
						
						position: relative;
						margin-bottom: 20px;
						
						@media screen and (min-width: 992px) {
						margin-bottom: 50px;
						}
						
						&:last-child {
							margin-bottom: 0;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
