.player-profile {
	
	&__captain-zone {
		
		&__team {
			
			&__team-files {
				
				&__files-list {
					
					&__file {
						
						padding: 5px 15px;
						
						&:nth-child(even) {
							background: #E6E6E6;
						}
						
						.file-name {
							margin-right: 20px;
							font-weight: 600;
						}
						
						.file-download-link {
							
							display: inline-flex;
							align-content: center;
							align-items: center;
							
							svg {
								margin-top: -2px;
								margin-left: 5px;
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
