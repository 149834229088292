.header-logo-component {
	
	display: flex;
	height: 60px;
	padding: 0 10px;
	align-items: center;
	justify-content: center;
	
	@media screen and (min-width: 992px) {
	height: 100px;
	margin: -20px 0;
	padding: 10px 0 10px 40px;
	background: var(--secondary-color);
	border-bottom-left-radius: 75px;
	}
	
	a {
		
		display: block;
		
		img {
			max-height: 50px;
			@media screen and (min-width: 992px) {
			max-height: 90px;
			}
		}
		
		&:hover {
			opacity: 0.7;
		}
		
	}
	
}
