.team-page {
	
	&__team-header {
		
		&__team-logo {
			
			min-height: 80px;
			padding: 0 0 10px 15px;
			text-align: center;
			justify-content: center;
			//background: #FFF;
			//box-shadow: 10px 10px 5px var(--primary-color);
			
			@media screen and (min-width: 992px) {
			min-height: 140px;
			padding: 15px 30px;
			}
			
			picture {
				
				height: 100%;
				justify-content: center;
				align-content: center;
				align-items: center;
				
				img {
					max-height: 80px;
					@media screen and (min-width: 992px) {
					max-height: 140px;
					}
				}
				
			}
			
		}
		
	}
}
