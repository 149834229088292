.leagues-schedules {

	&__leagues-and-rounds-switcher {
		
		&__rounds-switcher {
			
			position: relative;
			background: var(--primary-color);
			
			@media screen and (min-width: 992px) {
			height: 50px;
			//border-bottom: 5px solid var(--primary-color);
			}
		
			&__rounds-list {
				
				position: absolute;
				top: 40px;
				background: #FFF;
				z-index: 10000;
				border: 1px solid var(--primary-color);
				
				@media screen and (min-width: 992px) {
				top: 50px;
				}
				
				&__round {
					
					position: relative;
					font-weight: 600;
					text-transform: uppercase;
					border-bottom: 1px solid var(--primary-color);
					
					&:last-child {
						border-bottom: none;
					}
					
					a {
						
						display: block;
						width: 100%;
						padding: 5px 15px;
						line-height: 20px;
						
						@media screen and (min-width: 992px) {
						line-height: 24px;
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
