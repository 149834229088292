.header-socials-component {
	
	padding: 0 10px 0 0;
	@media screen and (min-width: 992px) {
	flex: 0 0 13.666667%;
	max-width: 13.666667%;
	height: 100px;
	margin: -20px 0;
	padding: 45px 10px 40px 0;
	background-size: auto 100%;
	}
	
	.socials-links-list {
		
		list-style: none;
		margin: 0;
		padding: 0;
		justify-content: right;
		@media screen and (min-width: 992px) {
		//justify-content: space-between;
		}
		
		.socials-list-item {
			
			width: 20px;
			height: 20px;
			margin-left: 10px;
			padding: 0;
			text-align: center;
			
			@media screen and (min-width: 992px) {
			margin-left: 5px;
			margin-bottom: 5px;
			}
			
			&:first-child {
				margin-left: 0;
			}
			
			a {
				
				display: inline-flex;
				vertical-align: top;
				width: 20px;
				height: 20px;
				justify-content: center;
				align-items: center;
				background: #FFF;
				border-radius: 3px;
				@media screen and (min-width: 992px) {
					display: flex;
				}
				
				img {
					vertical-align: top;
				}
				
				svg {
					vertical-align: middle;
				}
				
				&:hover {
					opacity: 0.7;
				}
				
			}
			
		}
		
	}
	
}
