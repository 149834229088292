.teams-and-players {
	
	&__content {
		
		&__teams-and-players {
			
			&__players {
				
				&__list {
					
					&__letter {
						
						margin-bottom: 25px;
						padding: 0 30px;
						
						@media screen and (min-width: 992px) {
						margin-bottom: 50px;
						padding: 0 100px 0 15px;
						}
						
						&__header {
							margin-bottom: 10px;
							padding: 0 0 10px;
							color: var(--primary-color);
							font-size: 22px;
							font-weight: 600;
							border-bottom: 2px solid var(--primary-color);
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
