.message-component {
	
	display: flex;
	margin: 0 auto;
	font-weight: 600;
	color: #333;
	justify-content: center;
	align-items: center;
	align-content: center;
	text-align: center;
	text-transform: uppercase;
	
	@media screen and (min-width: 992px) {
	max-width: 900px;
	}
	
	@media screen and (min-width: 1366px) {
	max-width: 1300px;
	}
	
	&.bordered {
		border-style: solid;
		border-width: 2px;
		border-color: var(--default-border-color);
	}
	
	&.white {
		color: #FFF;
		border-color: #FFF;
	}
	
	@for $i from 1 through 1000 {
		
		&.margin-vertical {
			&-#{$i} {
				margin: #{$i}px auto;
			}
		}
		
		&.margin-top {
			&-#{$i} {
				margin-top: #{$i}px;
			}
		}
		
		&.margin-bottom {
			&-#{$i} {
				margin-bottom: #{$i}px;
			}
		}
		
		&.padding {
			padding: 10px;
			&-#{$i} {
				padding: #{$i}px;
			}
		}
		
	}

	&.no-margin {
		margin: 0;
	}
	
	&.no-margin-top {
		margin: 0;
	}
	
	&.align-content-top {
		align-items: flex-start;
		align-content: flex-start;
	}
	
	&.error {
		color: #FF0000;
		border-color: #FF0000;
	}
	
	&.success {
		color: #1C7430;
		border-color: #1C7430;
	}
	
	&.warning {
		color: var(--primary-special-color);
		border-color: var(--primary-special-color);
	}
	
}
