.CookieConsent {
	
	@media screen and (max-width: 991px) {
	height: auto!important;
	}
	
	> div:first-child {
		margin: 10px 15px!important;
	}
	
	button {
		@media screen and (max-width: 991px) {
		margin: 0 15px 15px!important;
		}
	}
	
}
