.teams-and-players {
	
	position: relative;
	padding: 0;
	text-transform: uppercase;
	
	
	
	
	@media screen and (min-width: 992px) {}
	
	.teams-and-players-header {
		
		margin-top: 20px;
		padding: 0;
		border-top: 2px solid var(--primary-color);
		
		@media screen and (min-width: 992px) {
		margin-top: 0;
		padding: 10px 0;
		border-top: none;
		}
		
		.teams-and-players-switcher {
			
			display: flex;
			padding: 0;
			
			span {
				
				position: relative;
				display: inline-flex;
				width: 50%;
				height: 35px;
				padding: 10px 0;
				font-size: 14px;
				font-weight: 500;
				justify-content: center;
				line-height: 15px;
				cursor: pointer;
				
				@media screen and (min-width: 992px) {
				width: auto;
				height: 55px;
				margin-right: 50px;
				padding: 10px 0;
				font-size: 20px;
				justify-content: left;
				line-height: 30px;
				}
				
				&:last-child {
					margin-right: 0;
				}
				
				&:hover {
					color: var(--primary-special-color);
				}
				
				&.selected {
					
					color: #FFF;
					background: var(--secondary-color);
					
					@media screen and (min-width: 992px) {
					background: var(--primary-color);
					padding: 10px 50px;
					border-bottom: 5px solid var(--primary-special-color);
					}
					
					&::after, &::before {
						@media screen and (min-width: 992px) {
						content: "";
						position: absolute;
						top: 0;
						z-index: 1000;
						}
					}
					
					&::before {
						@media screen and (min-width: 992px) {
						right: -15px;
						border-top: 55px solid var(--primary-special-color);
						border-right: 15px solid transparent;
						}
					}
					
					&::after {
						@media screen and (min-width: 992px) {
						right: 0;
						border-top: 55px solid transparent;
						border-right: 15px solid var(--primary-special-color);
						}
					}
					
				}
				
			}
			
		}
		
	}
	
	.teams-and-players {
		
		padding: 10px 0;
		
		@media screen and (min-width: 992px) {
		padding: 10px 0 0;
		}
		
		.teams-and-players-teams {
			
			.teams-filters {
				
				margin-bottom: 15px;
				padding: 0 15px 10px 0;
				line-height: 32px;
				border-bottom: 1px solid var(--default-border-color);
				
				@media screen and (min-width: 992px) {
				margin-bottom: 30px;
				padding: 20px 15px 30px;
				}
				
				.teams-filters-label {
					padding-right: 0;
					font-size: 12px;
					font-weight: 600;
					text-align: right;
					@media screen and (min-width: 992px) {
					font-size: 16px;
					font-weight: 400;
					}
				}
				
				.teams-filters-filter {
					
					.form-control {
						
						line-height: 30px;
						
						@media screen and (min-width: 992px) {
						width: 300px;
						}
						
						&::placeholder {
							font-size: 10px;
							@media screen and (min-width: 992px) {
							font-size: 12px;
							}
						}
						
					}
				}
				
			}
			
			.teams-list {
				
				.teams-list-team {
					
					margin-bottom: 10px;
					padding: 10px;
					text-align: center;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 30px;
					padding: 10px 25px;
					}
					
					.team-logo {
						
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100px;
						margin-bottom: 10px;
						padding: 20px;
						
						@media screen and (min-width: 992px) {
						height: 130px;
						padding: 15px;
						}
						
						a {
							display: block;
							
							&:hover {
								opacity: 0.7;
							}
							
							img {
								max-width: 100%;
								max-height: 100px;
							}
							
						}
					}
					
				}
				
			}
			
		}
		
		.teams-and-players-players {
			
			.players-filters {
				
				margin-bottom: 15px;
				padding: 0 15px 10px 0;
				line-height: 32px;
				border-bottom: 1px solid var(--default-border-color);
				
				@media screen and (min-width: 992px) {
				margin-bottom: 30px;
				padding: 20px 15px 30px;
				}
				
				.players-filters-label {
					padding-right: 0;
					font-size: 12px;
					font-weight: 600;
					text-align: right;
					@media screen and (min-width: 992px) {
					font-size: 16px;
					font-weight: 400;
					}
				}
				
				.players-filters-filter {
					
					.form-control {
						
						line-height: 30px;
						
						@media screen and (min-width: 992px) {
							width: 300px;
						}
						
						&::placeholder {
							font-size: 10px;
							@media screen and (min-width: 992px) {
								font-size: 12px;
							}
						}
						
					}
				}
				
			}
			
			.players-list {
				
				.players-list-group {
					
					margin-bottom: 25px;
					padding: 0 30px;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 50px;
					padding: 0 100px 0 15px;
					}
					
					.players-list-group-header {
						margin-bottom: 10px;
						padding: 0 0 10px;
						color: var(--primary-color);
						font-size: 22px;
						font-weight: 600;
						border-bottom: 2px solid var(--primary-color);
					}
					
					.players-list-player {
						margin-bottom: 0;
						padding-left: 0;
						&:last-child {
							margin-bottom: 0;
						}
					}
					
				}
				
			}
			
		}
		
	}
	
}
