.teams-and-players {
	
	&__content {
		
		&__seasons-and-leagues {
			
			&__leagues {
				
				&__header {
					
					margin-bottom: 5px;
					padding: 0;
					flex-grow: initial;
					
					@media screen and (min-width: 992px) {
					margin-bottom: 20px;
					}
					
					&__text {
						
						position: relative;
						display: flex;
						padding: 5px 45px 5px 15px;
						font-size: 14px;
						color: #FFFFFF;
						line-height: 25px;
						background: var(--primary-color);
						
						@media screen and (min-width: 992px) {
						padding: 10px 75px 10px 30px;
						font-size: 18px;
						background: var(--secondary-color);
						border-bottom: 5px solid var(--primary-special-color);
						}
						
						&::before, &::after {
							position: absolute;
							top: 0;
							width: 10px;
							height: 50px;
							content: "";
						}
						
						&::before {
							@media screen and (min-width: 992px) {
							right: -10px;
							border-top: 50px solid var(--primary-special-color);
							border-right: 10px solid transparent;
							}
						}
						
						&::after {
							@media screen and (min-width: 992px) {
							right: 0;
							border-top: 50px solid transparent;
							border-right: 10px solid var(--primary-special-color);
							}
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
