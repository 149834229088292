.teams-and-players {

	&__content {
		
		&__teams-and-players {
			
			&__content-switcher {
			
				position: relative;
				margin-bottom: 20px;
				border-bottom: 2px solid var(--default-border-color);
				
				@media screen and (min-width: 992px) {
				margin-bottom: 40px;
				}
				
				&__button {
					
					padding: 10px 15px;
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					@media screen and (min-width: 992px) {
					padding: 10px 30px;
					font-size: 20px;
					line-height: 30px;
					border-bottom: 5px solid transparent;
					}
					
					&:not(.selected) {
						cursor: pointer;
					}
					
					&:hover {
						color: var(--primary-special-color);
					}
					
					&.selected {
						color: #FFF;
					}
					
					&.selected {
						
						position: relative;
						background: var(--primary-color);
						
						@media screen and (min-width: 992px) {
						padding-right: 40px;
						border-bottom: 5px solid var(--primary-special-color);
						}
						
						@media screen and (min-width: 992px) {
						
						&::before, &::after {
							content: "";
							position: absolute;
							top: 0;
							z-index: 1000;
						}
						
						&::before {
							right: -10px;
							border-top: 55px solid var(--primary-special-color);
							border-right: 10px solid transparent;
						}
						
						&::after {
							right: 0;
							border-top: 55px solid transparent;
							border-right: 10px solid var(--primary-special-color);
						}
						
						}
						
					}
					
				}
			
			}
			
		}
		
	}
	
}
