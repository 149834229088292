.game-details-admin {
	
	&__header {
	
		&__team {
		
			&__team-logo {
				
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 10px;
				padding: 15px;
				background: #FFF;
				border: 1px solid var(--default-border-color);
				
				img {
					height: 120px;
				}
				
			}
			
			&__team-name {
				margin-bottom: 10px;
				text-align: center;
				font-size: 14px;
				font-weight: 600;
			}
			
			&__team-points {
				text-align: center;
				font-size: 20px;
				font-weight: 600;
			}
		
		}
	
	}
}
