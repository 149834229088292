.team-page {
	
	&__team-header {
		
		&__team-name {
			
			padding-top: 5px;
			padding-left: 40px;
			
			@media screen and (min-width: 992px) {
			padding-top: 0;
			padding-left: 15px;
			}
			
			.team-name-content {
				
				height: 100%;
				align-items: start;
				align-content: start;
				
				@media screen and (min-width: 992px) {
				align-content: center;
				align-items: center;
				}
				
			}
			
			.team-name, .team-city {
				
				display: block;
				text-transform: uppercase;
				line-height: 30px;
				color: #FFF;
				
				@media screen and (min-width: 992px) {
				line-height: 40px;
				}
				
			}
			
			.team-name {
				font-size: 20px;
				@media screen and (min-width: 992px) {
				font-size: 40px;
				}
			}
			
			.team-city {
				font-size: 18px;
				@media screen and (min-width: 992px) {
				font-size: 30px;
				}
			}
			
		}
	}
}
